module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Confluence Conseil', // Navigation and Site Title
  titleAlt: 'Confluence Conseil', // Title for JSONLD
  description: 'Premier cabinet de conseil à Lyon en statut Scop, nous vous accompagnons tout au long du cycle de vie de vos projets, classique ou agile. Notre société est implantée à Lyon, Nantes et Clermont Ferrand.',
  headline: 'Spécialiste de l’Assistance à Maîtrise d’Ouvrage et de la Direction de Programmes et de Projets.', // Headline for schema.org JSONLD
  url: 'http://confluenceconseil.fr/', // Domain of your site. No trailing slash!
  siteLanguage: 'fr', // Language Tag on <html> element
  logo: '/logos/favicon.png', // Used for SEO
  ogLanguage: 'fr_FR', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'CConseil', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Confluence Conseil', // Author for schemaORGJSONLD
  themeColor: '#F8F8F8',
  backgroundColor: '#EBEDF2',

  twitter: '@ConfluenceCons', // Twitter Username
  facebook: 'Confluence Conseil', // Facebook Site Name
  googleAnalyticsID: 'UA-96916085-1',
  linkedin_url:'https://www.linkedin.com/company/confluence-conseil',
  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
