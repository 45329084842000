import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ListItemProfile from './ListItemProfile';

const List = styled.div`
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  list-style-type: none;
  margin-left: 0;
`;

export default class ListingProfile extends Component {
  render() {
    const { profiles } = this.props;
    return (
      <List>
        {profiles.map(profile => (
          <ListItemProfile key={profile.person_selected.uid} node={profile.person_selected.document[0]}/>
        ))}
      </List>
    );
  }
}

ListingProfile.propTypes = {
  profiles: PropTypes.array.isRequired,
};
