import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Wrapper from '../Wrapper';
import { HeroC, StyledBg, HeroInner, Aligner, Content } from './Elements';

function Hero({ hero }) {
  function HaveLink() {
    if (hero.data.link !== null) {
      return (
        <a target={hero.data.link.target} href={hero.data.link.url}>
          {hero.data.linklabel !== '' ? hero.data.linklabel : 'En savoir plus'}
        </a>
      );
    }
    return null;
  }
  return (
    <HeroC>
      <StyledBg>
        <Img fluid={hero.data.bghero.localFile.childImageSharp.fluid} />
      </StyledBg>
      <HeroInner>
        <Wrapper>
          <Aligner>
            <Img fluid={hero.data.logo.localFile.childImageSharp.fluid} alt={hero.data.title.text} />
            <Content dangerouslySetInnerHTML={{ __html: hero.data.content.html }} />
            <HaveLink />
          </Aligner>
        </Wrapper>
      </HeroInner>
    </HeroC>
  );
}

export default Hero;

Hero.propTypes = {
  hero: PropTypes.object.isRequired,
};
