import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'

export default class Categories extends Component {
  render() {
    const { categories } = this.props;
    return (
      <>
        <Link to={`/blog/${kebabCase(categories.uid)}`}>{categories.document[0].data.name}</Link>
      </>
    )
  }
}

Categories.propTypes = {
  categories: PropTypes.string.isRequired,
}
