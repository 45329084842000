import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Item = styled.div`
  margin-bottom: 1rem;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  //align-items: start;
  //align-content: center;
  //justify-content: center;
  align-items: center;
  
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
  
  &:nth-child(even){
    flex-direction: row-reverse;
    text-align: right;
    
    p{
      margin-left: 0;
      margin-right: 1rem;
    }
  }
  
  p, h3{
    margin-left: 1rem;
    max-width: 600px;
  // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //   margin-left: 0;
  //   text-align: center;
  // }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-left: 0;
    text-align: center;
  }

  }
  
  h3{
    margin-bottom: 0.6rem;
  }
  
  // &:nth-child(even){
  //   flex-direction: row-reverse;
  //   p, h3{
  //     margin-left: 0;
  //     margin-right: 2rem;
  //    
  //     // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //     //   margin-left: 0;
  //     //   text-align: center;
  //     // }
  //     @media (max-width: ${props => props.theme.breakpoints.s}) {
  //       margin-right: 0;
  //       text-align: center;
  //     }      
  //   }
  // }
    
  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    width: 240px;
    height: 100%; 
    margin: 0 auto;
  }
  
  img{
    //max-width: 148px;
    //max-height: 148px;
    display: inline-block;
    //border-radius: 50%;
    // border: 2px solid ${props => props.theme.colors.beige};
  }
`
const Pict = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: auto;
    margin-left: auto;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: auto;
    margin: 0 auto 1rem 0;
    text-align: center;
  }
`
const Infos = styled.div``

export default class ListTexteImageItem extends Component {
  render() {
    const { node } = this.props
    return (
      <Item>
        <Pict>
          <Img fluid={node.image.localFile.childImageSharp.fluid} />
        </Pict>
        <Infos>
          <div dangerouslySetInnerHTML={{ __html: node.texte.html }} />
        </Infos>
      </Item>
    )
  }
}

ListTexteImageItem.propTypes = {
  node: PropTypes.object.isRequired,
}
