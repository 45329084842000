import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Wrapper, Section } from '../../components'

const Content = styled.div`
  position: relative;
  padding-bottom: 35.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 0 auto 2rem auto;
  max-width: ${props => props.theme.maxWidthText};
`

const Player = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const BodyVideoPage = ({ input }) => (
  <Section bgcolor={input.primary.bgcolor}>
    <Wrapper>
      <div dangerouslySetInnerHTML={{ __html: input.primary.title.html }} />
      <div dangerouslySetInnerHTML={{ __html: input.primary.texte.html }} />
      <Content>
        <Player dangerouslySetInnerHTML={{ __html: input.primary.video.html }} />
      </Content>
    </Wrapper>
  </Section>
)

export default BodyVideoPage

BodyVideoPage.propTypes = {
  input: PropTypes.object.isRequired,
}
