import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Item = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: start;
  
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
  
  
  p, h3{
    margin-left: 2rem;
    
  // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //   margin-left: 0;
  //   text-align: center;
  // }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-left: 0;
    text-align: center;
  }

  }
  
  h3{
    margin-bottom: 0.6rem;
  }
  
  // &:nth-child(even){
  //   flex-direction: row-reverse;
  //   p, h3{
  //     margin-left: 0;
  //     margin-right: 2rem;
  //    
  //     // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //     //   margin-left: 0;
  //     //   text-align: center;
  //     // }
  //     @media (max-width: ${props => props.theme.breakpoints.s}) {
  //       margin-right: 0;
  //       text-align: center;
  //     }      
  //   }
  // }
    
  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    width: 152px;
    height: 152px; 
    margin: 0 auto;
  }
  
  img{
    max-width: 148px;
    max-height: 148px;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid ${props => props.theme.colors.beige};
  }
`
const Pict = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: auto;
    margin-left: auto;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: auto;
    margin: 0 auto 1rem 0;
    text-align: center;
  }
`
const Infos = styled.div`

ul{
  margin-left: 4rem;
}
`

export default class ListItemPerson extends Component {
  render() {
    const { node } = this.props
    const { type } = this.props
    const { name } = node.data
    const bio = node.data.bio.html
    const testimony = node.data.testimony.html
    const testimony1 = node.data.testimony1.html
    const type_t = type.selection_du_temoignage

    function IsJob() {
      if (type_t === 'job') {
        return (
          <div dangerouslySetInnerHTML={{ __html: testimony1 }} />
        )
      }
      return (<div dangerouslySetInnerHTML={{ __html: testimony }} />)
    }

    return (
      <Item>
        <Pict>
          <Img fluid={node.data.image.big.localFile.childImageSharp.fluid} />
        </Pict>
        <Infos>
          {/*<h3>{name}</h3>*/}
          {/*<div dangerouslySetInnerHTML={{ __html: testimony }} />*/}
          <IsJob/>
        </Infos>
      </Item>
    )
  }
}

ListItemPerson.propTypes = {
  node: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired,
}
