import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Image,
  Quote,
  Video,
  CodeBlock,
  BodyText,
  BodyArticlePage,
  BodyHeroPage,
  // BodyTeamPage,
  BodyBannerPage,
  BodyTextPage,
  BodyVideoPage,
  BodyContactPage,
  BodyPersonListPage,
  BodyBlogListPage,
  BodyBlogFeatured,
  BodyPartnerListPage,
  BodyGridPage,
  BodySubnavtPage,
  BodyPressListPage,
  BodyDocumentListPage,
  BodyHeroBlog,
  BodyOffreListPage,
  BodyOffreLienListPage,
  BodyProfileListPage,
  BodyTextListPage,
  BodyAgencyListPage,
  BodyOffreFeatured,
  BodySecteurListPage,
  BodyImgBannerPage,
  BodyChiffresPage,
  BodyTextImageDecalePage,
  BodyDomaineListPage,
  BodyMoyenListPage,
  BodyBlogLastPage,
  BodySeparator,
  BodyArticleBlog,
  // BodyBlogCat,
  ImageFull,
  // BodyMediasListPage,
  BodyBlogButon,
  BodyEmploiListPage,
  BodyReaListPage,
  BodyBlogSelectedListPage,
} from '../slices';

export default class SliceZone extends Component {
  render() {
    const { allSlices, persons, posts, partners, subnav, press, documents, offres, textes, agencies, secteurs, domains, last, jobs, realisations } = this.props;
    const slice = allSlices.map(s => {
      switch (s.slice_type) {
        // These are the API IDs of the slices
        case 'text':
          return <BodyText key={s.id} input={s} />;
        case 'textpage':
          return <BodyTextPage key={s.id} input={s} />;
        case 'bigbanner':
          return <BodyBannerPage key={s.id} input={s} />;
        case 'imgbanner':
          return <BodyImgBannerPage key={s.id} input={s} />;
        case 'videopage':
          return <BodyVideoPage key={s.id} input={s} />;
        case 'code_block':
          return <CodeBlock key={s.id} input={s} />;
        case 'contactpage':
          return <BodyContactPage key={s.id} input={s} />;
        case 'image':
          return <Image key={s.id} input={s} />;
        case 'imagefull':
          return <ImageFull key={s.id} input={s} />
        case 'quote':
          return <Quote key={s.id} input={s} />;
        case 'video':
          return <Video key={s.id} input={s} />;
        case 'articlepage':
          return <BodyArticlePage key={s.id} input={s} />;
        case 'hero':
          return <BodyHeroPage key={s.id} input={s} />;
        case 'heroblog':
          return <BodyHeroBlog key={s.id} input={s} />;
        case 'equipe':
          return <BodyTeamPage key={s.id} input={s} persons={persons} />;
        case 'blog_liste':
          return <BodyBlogListPage key={s.id} input={s} posts={posts} />;
        case 'rea_liste':
          return <BodyReaListPage key={s.id} input={s} realisations={realisations} />;
        case 'agency_liste':
          return <BodyAgencyListPage key={s.id} input={s} agencies={agencies} />;
        case 'liste_de_partenaires':
          return <BodyPartnerListPage key={s.id} input={s} posts={posts} partners={partners} />;
        case 'documents':
          return <BodyDocumentListPage key={s.id} input={s} posts={posts} documents={documents} />;
        case 'grid_3_4':
          return <BodyGridPage key={s.id} input={s} posts={posts} />;
        case 'mise_en_avant_blog':
          return <BodyBlogFeatured key={s.id} input={s} posts={posts} />;
        case 'mise_en_avant_offre':
          return <BodyOffreFeatured key={s.id} input={s} posts={posts} />;
        // case 'equipe':
        // return <BodyTeamPage key={s.id} input={s} persons={persons} />
        case 'liste_d_offres':
          return <BodyOffreListPage key={s.id} input={s} offres={offres} />;
        case 'liste_liens_offres':
          return <BodyOffreLienListPage key={s.id} input={s} offres={offres} />;
        case 'secteurs_d_activite':
          return <BodySecteurListPage key={s.id} input={s} secteurs={secteurs} />;
        case 'liste_de_personne':
          return <BodyPersonListPage key={s.id} input={s} persons={persons} />;
        case 'liste_image___texte':
          return <BodyTextListPage key={s.id} input={s} textes={textes} />;
        case 'liste_de_profiles':
          return <BodyProfileListPage key={s.id} input={s} persons={persons} />;
        case 'selection_d_articles':
          return <BodyBlogSelectedListPage key={s.id} input={s} posts={posts} />;
        case 'subnavigation':
          return <BodySubnavtPage key={s.id} input={s} subnav={subnav} />;
        case 'presse_liste':
          return <BodyPressListPage key={s.id} input={s} press={press} />;
        case 'chiffres':
          return <BodyChiffresPage key={s.id} input={s} press={press} />;
        case 'texte_et_image_en_decale':
          return <BodyTextImageDecalePage key={s.id} input={s} press={press} />;
        case 'domaines_d_intervention':
          return <BodyDomaineListPage key={s.id} input={s} domains={domains}/>;
        case 'moyen_accompagnement':
          return <BodyMoyenListPage key={s.id} input={s} domains={domains}/>;
        case 'blog_last':
          return <BodyBlogLastPage key={s.id} input={s} last={last} />;
        case 'separateur':
          return <BodySeparator key={s.id} input={s} />;
        case 'bouton':
          return <BodyBlogButon key={s.id} input={s} />;
        case 'articleblog':
          return <BodyArticleBlog key={s.id} input={s} />;
        case 'liste_des_offres_d_emploi':
          return <BodyEmploiListPage key={s.id} input={s} jobs={jobs}/>;
        default:
          return null;
      }
    });
    return <>{slice}</>;
  }
}

SliceZone.propTypes = {
  allSlices: PropTypes.array.isRequired,
  persons: PropTypes.object,
  offres: PropTypes.object,
  secteurs: PropTypes.object,
  domains: PropTypes.object,
  textes: PropTypes.object,
  partners: PropTypes.object,
  agencies: PropTypes.object,
  documents: PropTypes.object,
  posts: PropTypes.object,
  realisations: PropTypes.object,
  jobs: PropTypes.object,
  subnav: PropTypes.object,
  press: PropTypes.object,
  last: PropTypes.object,
};
