import React from 'react';
import PropTypes from 'prop-types';
import { FaTwitter, FaLinkedin } from 'react-icons/fa';
import { Section, Title } from "../../components";
import Form from '../../components/ContactForm/Form';
import { Col, FlexWrapper, ContactSocial } from './Elements';
import website from '../../../config/website';

const BodyContactPage = ({ input }) => (
  <Section bgcolor={input.primary.bgcolor}>
    <FlexWrapper direction={input.primary.direction}>
      <Title  title={input.primary.title.html} />
      <div dangerouslySetInnerHTML={{ __html: input.primary.texte.html }} />
        <Form />
    </FlexWrapper>
  </Section>
);

export default BodyContactPage;

BodyContactPage.propTypes = {
  input: PropTypes.object.isRequired,
};
