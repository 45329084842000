import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Wrapper from '../../components/Wrapper'
import Section from '../../components/Section'

export const FlexWrapper = styled(Wrapper)`
  display: flex;
  padding-top: 0rem;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
`

export const Content = styled.div`
  padding-top: 1rem;
`

export const StyledLinkCat = styled(Link)`
  display: inline-block;
  padding: 0 0 0.2rem 0;
  margin-bottom: 0.8rem;
  color: ${props => props.theme.colors.black};
  font-style: normal;
  font-size: 0.8rem;
  position: relative;

  &:before {
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s;
  }

  &.default {
    color: ${props => props.theme.colors.primary};
    &:before {
      background-color: ${props => props.theme.colors.primary};
    }
  }

  &.vert {
    color: ${props => props.theme.colors.yggVert};
    &:before {
      background-color: ${props => props.theme.colors.yggVert};
    }
  }

  &.bleuClair {
    color: ${props => props.theme.colors.yggBleuClair};
    &:before {
      background-color: ${props => props.theme.colors.yggBleuClair};
    }
  }

  &.bleu {
    color: ${props => props.theme.colors.yggBleu};
    &:before {
      background-color: ${props => props.theme.colors.yggBleu};
    }
  }

  &.or {
    color: ${props => props.theme.colors.yggOr};
    &:before {
      background-color: ${props => props.theme.colors.yggOr};
    }
  }

  &.violet {
    color: ${props => props.theme.colors.primary};
    &:before {
      background-color: ${props => props.theme.colors.primary};
    }
  }

  &.bronze {
    color: ${props => props.theme.colors.yggBronze};
    &:before {
      background-color: ${props => props.theme.colors.yggBronze};
    }
  }
  &.cuivre {
    color: ${props => props.theme.colors.yggCuivre};
    &:before {
      background-color: ${props => props.theme.colors.yggCuivre};
    }
  }
  &.magenta {
    color: ${props => props.theme.colors.yggMagenta};
    &:before {
      background-color: ${props => props.theme.colors.yggMagenta};
    }
  }

  &.turquoise {
    color: ${props => props.theme.colors.yggTurquoise};
    &:before {
      background-color: ${props => props.theme.colors.yggTurquoise};
    }
  }
  &.rouge {
    color: ${props => props.theme.colors.yggRouge};
    &:before {
      background-color: ${props => props.theme.colors.yggRouge};
    }
  }

  &:hover {
    text-decoration: none;
    &:before {
      width: 100%;
    }
  }
`

export const SectionPadding = styled(Section)`
  padding-top: 0rem;
  padding-bottom: 0rem;
`
export const Item = styled.article`
    padding-top: 3rem;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.03);
  margin-bottom: 1.45rem;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.1);
  }

  p {
    min-height: 78px;
  }


`
export const StyledImg = styled.article`

    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;

    .gatsby-image-wrapper {
      max-width: 100%;
      height:auto;
    }
`

export const ItemWrapper = styled.article`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  padding: 1rem 0;
  font-size: 1rem;
  font-weight: normal;
`


export const Headline = styled.div`
  margin-bottom: 0.6rem;
  font-size: 0.8rem;

  &:first-letter {
    text-transform: uppercase;
  }

  a {
    color: ${props => props.theme.colors.grey};
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
  }
`

export const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.black};
  font-style: normal;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.777rem;
  }
`

export const StyledTitle = styled.div`

  min-height: 3.7rem;

  h2 {
    color: ${props => props.theme.colors.footer};
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 0.6rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1rem;
  }
`