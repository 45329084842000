import React from 'react';
import PropTypes from 'prop-types';
import TruncateMarkup from 'react-truncate-markup';
import {
  FlexWrapper,
  Item,
  ItemWrapper,
  StyledLink,
  StyledTitle,
  Headline,
  StyledImg,
  Col,
} from './Elements';

function BodyOffreFeatured({ input }) {
  return (
    <>
      <FlexWrapper>
        {/* <Content dangerouslySetInnerHTML={{ __html: input.primary.offretitle.html }} /> */}
        {/* <Content dangerouslySetInnerHTML={{ __html: input.primary.offreintro.html }} /> */}
        <StyledLink
          to={`${/offres/ + input.primary.offre.document[0].uid}`}
        >
          <Item>
            <Col>
              <StyledImg
                fluid={input.primary.offre.document[0].data.thumb.localFile.childImageSharp.fluid}
                alt={input.primary.offre.document[0].data.title.text}
                title={input.primary.offre.document[0].data.title.text}
              />
            </Col>
            <Col>
              <ItemWrapper>
                <StyledTitle>
                  <TruncateMarkup lines={1}>
                    <h2>{input.primary.offre.document[0].data.title.text}</h2>
                  </TruncateMarkup>
                </StyledTitle>
                <TruncateMarkup lines={4}>
                  <p>{input.primary.offre.document[0].data.shortdesc.text}</p>
                </TruncateMarkup>
              </ItemWrapper>
            </Col>
          </Item>
        </StyledLink>
      </FlexWrapper>
    </>
  );
}

export default BodyOffreFeatured;

BodyOffreFeatured.propTypes = {
  input: PropTypes.object.isRequired,
};
