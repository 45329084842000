import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FlexWrapper, SectionPadding, Content, ListControl } from './Elements';
import { ListingCard, Title } from "../../components";

function BodyBlogListPage({ input, posts }) {
  function HaveMore() {
    if (input.primary.more !== 'no') {
      return (
        <ListControl className="button">
          <Link to="/blog">
            En voir plus
          </Link>
        </ListControl>
      );
    }
    return null;
  }
  function HaveTitle() {
    if (input.primary.title.text !== "") {
      return (
        <Title title={input.primary.title.html} color={input.primary.bgcolor} />
      );
    }
    return null;
  }
  return (
    <SectionPadding bgcolor={input.primary.bgcolor}>
      <FlexWrapper>
        <HaveTitle />
        <ListingCard posts={posts.edges} />
        <HaveMore />
      </FlexWrapper>
    </SectionPadding>
  );
}

export default BodyBlogListPage;

BodyBlogListPage.propTypes = {
  input: PropTypes.object.isRequired,
  posts: PropTypes.object.isRequired,
};
