import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Link } from "gatsby";
import TruncateMarkup from "react-truncate-markup";

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.greyDark};
  font-style: normal;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.777rem;
  }
`;
const Item = styled.div`
  //min-height: 540px;
  padding: 0.6rem;
  //border-radius: 4px;
  // background-color: ${props => props.theme.colors.white};
  //box-shadow: 0 5px 35px rgba(0, 0, 0, 0.03);
  margin-bottom: 0.6rem;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  flex: 1 0 20%;
  position: relative;

  //&:hover {
  //  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.2);
  //  transform: translateY(-10px);
  //}

  p {
    max-width: 80%;
  }
  
 
  // &:nth-child(even){
  //   flex-direction: row-reverse;
  //   p, h3{
  //     margin-left: 0;
  //     margin-right: 2rem;
  //    
  //     // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //     //   margin-left: 0;
  //     //   text-align: center;
  //     // }
  //     @media (max-width: ${props => props.theme.breakpoints.s}) {
  //       margin-right: 0;
  //       text-align: center;
  //     }      
  //   }
  // }
    
  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    width: 128px;
    height: auto; 
    margin: 0 auto 0.4rem;
  }
  
  img{
    max-width: 128px;
    max-height: 128px;
    display: inline-block;
    // border: 2px solid ${props => props.theme.colors.beige};
  }
`
const Pict = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: auto;
    margin-left: auto;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: auto;
    margin: 0 auto 1rem 0;
    text-align: center;
  }
`

const StyledTitle = styled.div`
  //min-height: 3.7rem;

  h2 {
    color: ${props => props.theme.colors.primary};
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1.7rem;
    margin-bottom: 0.6rem;
    text-align: center;
    
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      font-size: 0.8rem!important;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1rem;
  }
`;

const ItemWrapper = styled.article`
  //padding: 1rem 1rem 0 1rem;
  font-size: 0.9rem;
  font-weight: normal;
`;

export default class ListItemChiffres extends Component {
  render() {
    const { node } = this.props
    return (
        <Item>
          <Pict>
            <Img fluid={node.image.localFile.childImageSharp.fluid} style={{height: 90, width: 90}} alt={node.name}/>
          </Pict>
          <ItemWrapper>
            <StyledTitle>
              <TruncateMarkup lines={2}>
                <h2>{node.name}</h2>
              </TruncateMarkup>
            </StyledTitle>
          </ItemWrapper>
        </Item>
    )
  }
}

ListItemChiffres.propTypes = {
  node: PropTypes.object.isRequired,
}
