import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Headroom from 'react-headroom';
import styled from '@emotion/styled';
import { headroom } from '../styles';
import logo from '../../static/logos/logo.svg'
import MenuItems from "./MenuItems/MenuItems";


const StyledHeader = styled.div`
  ${headroom};
  width: 100%;
  height: 93px;
  background-color: ${props => (props.invert ? props.theme.colors.transparent : props.theme.colors.white)};
  position: ${props => (props.invert ? 'fixed' : 'relative')};
  z-index: 999;

  a{
      font-size: 0.7rem;
      line-height: 2.6rem;
      font-weight: bold;
      text-transform: uppercase;
      color: ${props => (props.invert ? props.theme.colors.white : props.theme.colors.greyDarker)};
      transition: all 0.5s;
      display: block;
      position: relative;

      &:after{
        content: "";
        width: 0;
        height: 2px;
        display: block;
        background-color: ${props => props.theme.colors.primary};
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.5s;
      }

      &.joinus{
        padding-left: 13px;
        padding-right: 13px;
        border-radius: 3px;
        color: ${props => props.theme.colors.primary};
        border: 2px solid ${props => props.theme.colors.primary};
        transition: all 0.3s;

        &:hover{
          color: #ffffff;
          background-color: ${props => props.theme.colors.primary};
        }
        
        &:after{
        display: none;
        }
      }

      &.intra{
        padding-left: 13px;
        padding-right: 13px;
        border-radius: 3px;
        color: ${props => props.theme.colors.footer};
        border: 2px solid ${props => props.theme.colors.footer};
        transition: all 0.3s;
        margin-left: -1.2rem;
  
        &:hover{
          color: #ffffff;
          background-color: ${props => props.theme.colors.footer};
        }
  
        &:after{
          display: none;
        }
      }
      
      &.logo{
        &:after{
          display: none;
        }
      }
      
      &:hover, &.active{
        // color: ${props => (props.invert ? props.theme.colors.greyDark : props.theme.colors.primary)};
        text-decoration: none;

        &:after{
          width: 100%;
        }
      }
  }
  
  header{
    width: 100%;
    height: 93px;
    padding: 10px;
    background-color: ${props => (props.invert ? props.theme.colors.transparent : props.theme.colors.white)};
    box-shadow: 0 1px 0 0 rgba(0,0,0,.066);
  }

  img{
    height: 70px;
  }
`;

const StyledHeaderWrapper = styled.div`
  width: 100%;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;



const MenuWrapper = styled.nav`

  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    
    li{
      display: inline-block;
      margin-left: 1rem;
      padding: 0.6rem 0.8rem;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none  
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: none  
  }
`;


class Header extends Component {
  render() {
    const { invert } = this.props;
    return (
      <StyledHeader invert={invert}>
        <Headroom>
          <header>
            <StyledHeaderWrapper>
              <Link className="logo" to="/">
                <img src={logo} alt="Confluence Conseil"/>
              </Link>
              <MenuWrapper>
                <MenuItems/>
              </MenuWrapper>
            </StyledHeaderWrapper>
          </header>
        </Headroom>
      </StyledHeader>
    );
  }
}

export default Header;

Header.propTypes = {
  invert: PropTypes.bool,
};

Header.defaultProps = {
  invert: false,
};
