import styled from '@emotion/styled'
import Wrapper from '../../components/Wrapper'
import Section from '../../components/Section'

export const FlexWrapper = styled(Wrapper)`
  display: flex;
  padding-top: 2rem;
  //justify-content: space-between;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
`

export const Content = styled.div`
  padding: 1rem 0;
`

export const SectionPadding = styled(Section)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`
export const ListControl = styled.div`
  text-align: right;

  a {
    width: fit-content;
    padding-left: 1.9rem;
    justify-items: left;
    font-size: 0.9rem;
    color: ${props => props.theme.colors.greyDark};
    position: relative;

    &:hover {
    }
  }
`
