import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ListItemSecteur from './ListItemSecteur';

const List = styled.div`
width: 100%;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  list-style-type: none;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  //flex-grow: 6;
`;

export default class ListingSecteur extends Component {
  render() {
    const { secteurs } = this.props;
    return (
      <List>
        {secteurs.map(secteur => (
          <ListItemSecteur key={secteur.area.uid} node={secteur.area.document[0]} />
        ))}
      </List>
    );
  }
}

ListingSecteur.propTypes = {
  secteurs: PropTypes.array.isRequired,
};
