import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ListItemPartner from './ListItemPartner';

const List = styled.div`
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  list-style-type: none;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  
  // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //   flex-direction: column;
  // }
  // @media (max-width: ${props => props.theme.breakpoints.s}) {
  //   flex-direction: column;
  // }
`;

export default class ListingPartner extends Component {
  render() {
    const { partners } = this.props;
    return (
      <List>
        {partners.map(partner => (
          <ListItemPartner key={partner.partners.uid} node={partner.partners.document[0]} />
        ))}
      </List>
    );
  }
}

ListingPartner.propTypes = {
  partners: PropTypes.array.isRequired,
};
