/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Global, css } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import '@reach/skip-nav/styles.css';
import Popup from 'reactjs-popup';
import BurgerIcon from './Burger/Burger';
import Menu from './Menu/Menu';

import SkipNavLink from './SkipNavLink';
import { theme, reset } from '../styles';
import { Footer, SEO } from '.';



const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.greyDarker};
    background-color: ${theme.colors.bg};
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primary};
  }
  a {
    color: ${theme.colors.primary};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 700;
    &:hover,
    &:focus {
      //text-decoration: underline;
    }
    
    
  }
  @media (max-width: ${theme.breakpoints.m}) {
    html {
      font-size: 16px !important;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    h1 {
      font-size: 2.369rem !important;
    }
    h2 {
      font-size: 1.777rem !important;
    }
    h3 {
      font-size: 1.333rem !important;
    }
    h4 {
      font-size: 1rem !important;
    }
    h5 {
      font-size: 0.75rem !important;
    }
    h6 {
      font-size: 0.563rem !important;
    }
  }
`;

const PureLayout = ({ children,  customSEO }) => (
  <ThemeProvider theme={theme}>
    <>
      <Global styles={globalStyle} />
      <SkipNavLink />
      {!customSEO && <SEO />}
      <Popup
        modal
        overlayStyle={{ background: 'rgba(255,255,255,1' }}
        contentStyle={{ width: '100%', border: 'none', background: 'none' }}
        closeOnDocumentClick={false}
        trigger={open => <BurgerIcon open={open} />}
      >
        {close => <Menu close={close} />}
      </Popup>
      {children}
      <Footer>

      </Footer>
    </>
  </ThemeProvider>
);

class Layout extends Component {
  render() {
    return (

          <PureLayout {...this.props} >
            {this.props.children}
          </PureLayout>

    );
  }
}

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};

PureLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  customSEO: PropTypes.bool,
};

PureLayout.defaultProps = {
  customSEO: false,
};
