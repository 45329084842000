import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Content = styled.div`
  margin: 2rem auto 1rem auto;
  max-width: ${props => props.theme.maxWidth};
  padding: 1.8rem 0;
    
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  
    .center{
    text-align: center!important;
  }
`
const Image = ({ input }) => (
  <Content>
    <Img fluid={input.primary.image.localFile.childImageSharp.fluid} />
  </Content>
)

export default Image

Image.propTypes = {
  input: PropTypes.object.isRequired,
}
