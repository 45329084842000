import styled from '@emotion/styled';
import { Link } from 'gatsby';

export const StyledPagination = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin: 1rem 0;
`;

export const StyledCount = styled.div`
  width: fit-content;
  padding: 0.6rem 1.2rem;
  font-size: 0.9rem;
  font-weight: 600;
`;

export const StyledBlank = styled.div`
  width: 160px;
  padding: 0.6rem 1.2rem;
  box-sizing: border-box;
  opacity: 0;
`;

export const StyledLink = styled(Link)`
  width: 170px;
  padding: 0.6rem 1.2rem;
  box-sizing: border-box;
  border-radius: 3px;
  border: none;
  transition: all 0.5s;
  position: relative;
  text-transform: uppercase;
  color: ${props => props.theme.colors.greyDark};

  span {
    color: ${props => props.theme.colors.greyDark};
    font-size: 1.3rem;
    font-weight: 600;
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    transition: all 0.3s;
  }

  &.left {
    padding-left: 1.9rem;
    text-align: left;
    span {
      left: 10px;
    }
  }

  &.right {
    padding-right: 1.9rem;
    text-align: right;
    span {
      right: 10px;
    }
  }

  &:disabled {
    color: ${props => props.theme.colors.greyDark};
  }

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.greyDark};

    span {
      color: ${props => props.theme.colors.primary};
    }

    &.left {
      span {
        left: 0px;
      }
    }

    &.right {
      span {
        right: 0px;
      }
    }
  }
`;
