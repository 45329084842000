import React from 'react';
import styled from '@emotion/styled';
import { FaTwitter, FaGithub, FaLinkedin } from 'react-icons/fa';
import website from '../../../config/website';

const StyledSocial = styled.div`
  a {
    color: ${props => props.theme.colors.white};
    font-size: 1.2rem;
    margin: 0 0 0 1rem;
    transition: color 0.5s;

    &:hover {
      &.facebook {
        color: ${props => props.theme.colors.facebook};
      }
      &.twitter {
        color: ${props => props.theme.colors.twitter};
      }
      &.linkedin {
        color: ${props => props.theme.colors.linkedin};
      }
      &.openoox {
        color: ${props => props.theme.colors.openoox};
      }
    }
  }
`;

const Social = () => (
  <StyledSocial>
    {/*<a*/}
    {/*  target="_blank"*/}
    {/*  rel="noopener noreferrer"*/}
    {/*  className="twitter"*/}
    {/*  title="Retrouvez Confluence Conseil sur Twitter"*/}
    {/*  href={website.twitter_url}*/}
    {/*>*/}
    {/*  {FaTwitter()}*/}
    {/*</a>*/}
    {/*<a*/}
    {/*  target="_blank"*/}
    {/*  rel="noopener noreferrer"*/}
    {/*  className="github"*/}
    {/*  title="Retrouvez Confluence Conseilsur Github"*/}
    {/*  href={website.github_url}*/}
    {/*>*/}
    {/*  {FaGithub()}*/}
    {/*</a>*/}
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="linkedin"
      title="Retrouvez Confluence Conseil sur Linkedin"
      href={website.linkedin_url}
    >
      {FaLinkedin()}
    </a>
  </StyledSocial>
);

export default Social;
