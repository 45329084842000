import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ListAgencyItem } from '.';

const List = styled.div`
  margin-top: 2rem;
  margin-bottom: 0rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export default class ListingAgency extends Component {
  render() {
    const { agencies, display } = this.props;
    return (
      <List>
        {agencies.map(agency => {
          return <ListAgencyItem key={agency.node.uid} node={agency.node} display={display}/>;
        })}
      </List>
    );
  }
}

ListingAgency.propTypes = {
  agencies: PropTypes.array.isRequired,
  display: PropTypes.string.isRequired,
};
