import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Content = styled.div`
  padding-top: 1.1rem;
  padding-bottom: 1.5rem;
`

const StyledImg = styled(Img)`
  //max-width: 600px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
`

const StyledCaption = styled.div`
  max-width: 600px;
  text-align: left;
  margin: 0.6rem auto 0 auto;
  font-size: 0.8rem;
`

const ImageFull = ({ input }) => (
  <Content>
    <StyledImg fluid={input.primary.image.localFile.childImageSharp.fluid} alt={input.primary.image.alt} />
    <StyledCaption>{input.primary.image.copyright}</StyledCaption>
  </Content>
)

export default ImageFull

ImageFull.propTypes = {
  input: PropTypes.object.isRequired,
}
