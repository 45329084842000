import React from 'react';
import PropTypes from 'prop-types';
import { FlexWrapper, Content } from './Elements';
import ListDocument from '../../components/ListDocument';
import Section from '../../components/Section';

function BodyDocumentListPage({ input }) {
  return (
    <Section>
      <FlexWrapper>
        {/* <Content dangerouslySetInnerHTML={{ __html: input.primary.title.html }}/> */}
        <Content dangerouslySetInnerHTML={{ __html: input.primary.title.html }} />
        <Content dangerouslySetInnerHTML={{ __html: input.primary.desc.html }} />
      </FlexWrapper>
      <FlexWrapper>
        <ListDocument documents={input.items} />
      </FlexWrapper>
    </Section>
  );
}

export default BodyDocumentListPage;

BodyDocumentListPage.propTypes = {
  input: PropTypes.object.isRequired,
};
