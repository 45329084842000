import React from 'react';
import PropTypes from 'prop-types';
import { FlexWrapper, SectionPadding, Content } from './Elements';
import ListingOffre from "../../components/Listoffre";

function BodyOffreListPage({ input }) {
  return (
    <SectionPadding>
      <FlexWrapper>
        <ListingOffre offres={input.items} />
      </FlexWrapper>
    </SectionPadding>
  );
}

export default BodyOffreListPage;

BodyOffreListPage.propTypes = {
  input: PropTypes.object.isRequired,
};
