import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FlexWrapper, SectionPadding, Content, ListControl } from './Elements';
import { Title } from "../../components";
import ListingJob from "../../components/ListingJob";

function BodyEmploiListPage({ input, jobs }) {
  function HaveTitle() {
    if (input.primary.title.text !== "") {
      return (
        <Title title={input.primary.title.html} color={input.primary.bgcolor} />
      );
    }
    return null;
  }
  return (
    <SectionPadding bgcolor={input.primary.bgcolor}>
      <FlexWrapper>
        <HaveTitle />
        <ListingJob jobs={jobs.edges} />
      </FlexWrapper>
    </SectionPadding>
  );
}

export default BodyEmploiListPage;

BodyEmploiListPage.propTypes = {
  input: PropTypes.object.isRequired,
  jobs: PropTypes.object.isRequired,
};
