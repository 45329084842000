import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import TruncateMarkup from 'react-truncate-markup'
import Moment from 'react-moment'
import { Authors } from '../../components/ListingCard'
import {
  FlexWrapper,
  Item,
  ItemWrapper,
  StyledLink,
  StyledTitle,
  Headline,
  StyledLinkCat,
  StyledImg,
} from './Elements'
import 'moment-timezone'
import 'moment/locale/fr'

function BodyBlogFeatured({ input }) {
  return (
    <>
      <FlexWrapper>
        {/* <Content dangerouslySetInnerHTML={{ __html: input.primary.featuredtitle.html }} /> */}
        {/* <Content dangerouslySetInnerHTML={{ __html: input.primary.featuredintro.html }} /> */}
        <StyledLink
          to={`${/blog/ + input.primary.featured.document[0].data.categorie.uid}/${
            input.primary.featured.document[0].uid
          }`}
        >
          <Item>
            {/* <Col> */}
            <StyledImg>
              <Img fluid={input.primary.featured.document[0].data.main.localFile.childImageSharp.fluid} />
            </StyledImg>
            {/* </Col> */}
            {/* <Col> */}
            <ItemWrapper>
              <StyledTitle>
                <TruncateMarkup lines={3}>
                  <h2>{input.primary.featured.document[0].data.title.text}</h2>
                </TruncateMarkup>
              </StyledTitle>
              <Headline>
                <Link to={`${/auteur/ + input.primary.featured.document[0].data.authors[0].author.document[0].uid}`}>
                  {input.primary.featured.document[0].data.authors[0].author.document[0].data.name},
                </Link>
                <span> Il y a </span>
                <Moment locale="fr" fromNow ago>
                  {input.primary.featured.document[0].first_publication_date}
                </Moment>
              </Headline>
              <TruncateMarkup lines={5}>
                <p>{input.primary.featured.document[0].data.shortdesc.text}</p>
              </TruncateMarkup>
              <StyledLinkCat
                className={input.primary.featured.document[0].data.categorie.document[0].data.color}
                to={`${/blog/ + input.primary.featured.document[0].data.categorie.uid}`}
              >
                # {input.primary.featured.document[0].data.categorie.document[0].data.name}
              </StyledLinkCat>
            </ItemWrapper>
            {/* </Col> */}
          </Item>
        </StyledLink>
      </FlexWrapper>
    </>
  )
}

export default BodyBlogFeatured

BodyBlogFeatured.propTypes = {
  input: PropTypes.object.isRequired,
}
