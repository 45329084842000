import { css } from '@emotion/core';

const headroom = css`
.headroom {
  top: 0;
  left: 0;
  right: 0;
  zIndex: 1;
}
.headroom--unfixed {
  position: relative;
  transform: translateY(0);
}
.headroom--scrolled {
  transition: transform 200ms ease-in-out;
  height: 93px;
  background-color: white;
        

}

.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
  a{
    color: #595C62;
    transition: color 0.5s;
    &:hover{
      color: #48c8f5;
    }
  }
}
`;

export default headroom;
