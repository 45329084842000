import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Section from '../../components/Section';
import { FlexWrapper, Col, Aligner, Content } from './Elements';

function BodyArticlePage({ input }) {
  function HaveLink() {
    if (input.primary.link !== null) {
      return (
        <a target={input.primary.link.target} href={input.primary.link.url}>
          {input.primary.linklabel !== '' ? input.primary.linklabel : 'Voir la suite'}
        </a>
      );
    }
    return null;
  }

  return (
    <Section>
      <FlexWrapper direction={input.primary.direction}>
        <Col>
          <Img
            fluid={input.primary.image.carre.localFile.childImageSharp.fluid}
            alt={input.primary.title1.text}
            title={input.primary.title1.text}
          />
        </Col>
        <Col>
          <Aligner>
            <div dangerouslySetInnerHTML={{ __html: input.primary.title1.html }} />
            <Content dangerouslySetInnerHTML={{ __html: input.primary.text.html }} />
            <HaveLink />
          </Aligner>
        </Col>
      </FlexWrapper>
    </Section>
  );
}

export default BodyArticlePage;

BodyArticlePage.propTypes = {
  input: PropTypes.object.isRequired,
};
