import styled from '@emotion/styled'

export const StyledSeparator = styled.div`
  width: ${props => (props.type === "small" ? '30%' : '100%')};;
  height: 1px;
  margin: 0 auto 1rem auto;
  background-color: ${props => props.theme.colors.primary};
  background-color: ${props => (props.color === "vert" ? props.theme.colors.yggVert : '')};
  background-color: ${props => (props.color === "bronze" ? props.theme.colors.yggBronze : '')};
  background-color: ${props => (props.color === "bleuClair" ? props.theme.colors.yggBleuClair : '')};
  background-color: ${props => (props.color === "bleu" ? props.theme.colors.yggBleu : '')};
  background-color: ${props => (props.color === "or" ? props.theme.colors.yggOr : '')};
  background-color: ${props => (props.color === "violet" ? props.theme.colors.yggViolet : '')};
  background-color: ${props => (props.color === "cuivre" ? props.theme.colors.yggCuivre : '')};
  background-color: ${props => (props.color === "turquoise" ? props.theme.colors.yggTurquoise : '')};
  background-color: ${props => (props.color === "rouge" ? props.theme.colors.yggRouge : '')};
  background-color: ${props => (props.color === "magenta" ? props.theme.colors.yggMagenta : '')};
`

export const StyledDivider = styled.div`
//   width: ${props => (props.type === 'small' ? '30%' : '100%')};
  margin: 1rem auto 1rem auto;
  text-align: center;

  svg {
    &.default {
      g {
        fill: ${props => props.theme.colors.primary};
      }
    }
    &.vert {
      g {
        fill: ${props => props.theme.colors.yggVert};
      }
    }
    &.bleuClair {
      g {
        fill: ${props => props.theme.colors.yggBleuClair};
      }
    }
    &.bleu {
      g {
        fill: ${props => props.theme.colors.yggBleu};
      }
    }
    &.or {
      g {
        fill: ${props => props.theme.colors.yggOr};
      }
    }
    &.violet {
      g {
        fill: ${props => props.theme.colors.primary};
      }
    }
    &.bronze {
      g {
        fill: ${props => props.theme.colors.yggBronze};
      }
    }
    &.cuivre {
      g {
        fill: ${props => props.theme.colors.yggCuivre};
      }
    }
    &.magenta {
      g {
        fill: ${props => props.theme.colors.yggMagenta};
      }
    }
    &.turquoise {
      g {
        fill: ${props => props.theme.colors.yggTurquoise};
      }
    }
    &.rouge {
      g {
        fill: ${props => props.theme.colors.yggRouge};
      }
    }
  }
`