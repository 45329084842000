import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Link } from "gatsby";
import TruncateMarkup from "react-truncate-markup";

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.greyDark};
  font-style: normal;
  

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.777rem;
  }
`;
const Item = styled.div`
  min-height: 600px;
  padding: 20px 0 0 0;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.03);
  margin-bottom: 1.45rem;
  transition: all 0.3s;
  position: relative;

  &:hover {
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.2);
    transform: translateY(-10px);
  }

  p {
    min-height: 78px;
    margin-left: 1.45rem;
  }
  
 
  // &:nth-child(even){
  //   flex-direction: row-reverse;
  //   p, h3{
  //     margin-left: 0;
  //     margin-right: 2rem;
  //    
  //     // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //     //   margin-left: 0;
  //     //   text-align: center;
  //     // }
  //     @media (max-width: ${props => props.theme.breakpoints.s}) {
  //       margin-right: 0;
  //       text-align: center;
  //     }      
  //   }
  // }
    
  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    width: 80%;
    height: auto; 
    margin: 0 auto;
  }
  
  img{
    //max-width: 128px;
    //max-height: 128px;
    display: inline-block;
    // border: 2px solid ${props => props.theme.colors.beige};
  }
`
const Pict = styled.div`
display: block;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: auto;
    margin-left: auto;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: auto;
    margin: 0 auto 1rem 0;
    text-align: center;
  }
`

const Col = styled.div`
  margin-bottom: 2rem;
  //padding-bottom: 10px;
  position: relative;
  z-index: 100;
  flex-basis: calc(99.9% * 1 / 2 - 1.5rem);
  max-width: calc(99.9% * 1 / 2 - 1.5rem);
  width: calc(99.9% * 1 / 2 - 1.5rem);
  text-align: ${props => (props.center ? 'center' : '')};
  text-align: ${props => (props.right ? 'right' : '')};

  @media (max-width: 1000px) {
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    max-width: calc(99.9% * 1 / 2 - 1rem);
    width: calc(99.9% * 1 / 2 - 1rem);
  }
  @media (max-width: 700px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
`;

const StyledTitle = styled.div`
  min-height: 3.7rem;

  h2 {
    color: ${props => props.theme.colors.primary};
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.7rem;
    margin-bottom: 0.6rem;
    text-align: center;
    
      @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.2rem!important;
  }
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1rem;
  }
`;

const ItemWrapper = styled.article`
  padding: 1rem 3rem 0 3rem;
  font-size: 0.9rem;
  font-weight: normal;
`;

const StyledMore = styled.div`
width: fit-content;
  font-size: 0.7rem;
  line-height: 2.6rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.5s;
  display: block;
  position: relative;
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 3px;
  color: ${props => props.theme.colors.primary};
  border: 2px solid ${props => props.theme.colors.primary};
  transition: all 0.3s;
  margin: 0 auto 1rem auto;
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);

    &:hover{
      color: ${props => props.theme.colors.white}!important;
      background-color: ${props => props.theme.colors.primary};
    }
`;

export default class ListItemListLienOffre extends Component {
  render() {
    const { node } = this.props
    return (
      <Col>
        <StyledLink to={`${/offres/ + node.uid}`}>
        <Item>
          <Pict>
            <Img fluid={node.data.thumb.full.localFile.childImageSharp.fluid} />
          </Pict>
          <ItemWrapper>
            <StyledTitle>
              <TruncateMarkup lines={2}>
                <h2>{node.data.title.text}</h2>
              </TruncateMarkup>
            </StyledTitle>
            <TruncateMarkup lines={3}>
              <div dangerouslySetInnerHTML={{ __html: node.data.liste_description.html }}/>
            </TruncateMarkup>
          </ItemWrapper>
          <StyledMore>en savoir plus</StyledMore>
        </Item>
        </StyledLink>
      </Col>
    )
  }
}

ListItemListLienOffre.propTypes = {
  node: PropTypes.object.isRequired,
}
