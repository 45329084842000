import React from 'react';
import PropTypes from 'prop-types';
import { FlexWrapper, SectionPadding, Content } from './Elements';
import ListingText from "../../components/ListText";
import { Title } from "../../components";

function BodyTextListPage({ input }) {
  return (
    <SectionPadding>
      <FlexWrapper>
        <Title title={input.primary.title.html}  />
         {/*<Content dangerouslySetInnerHTML={{ __html: input.primary.title.html }}/>*/}
        {/*<Content dangerouslySetInnerHTML={{ __html: input.primary.selectedtext.html }} />*/}
      </FlexWrapper>
      <FlexWrapper>
        <ListingText textes={input.items} />
      </FlexWrapper>
    </SectionPadding>
  );
}

export default BodyTextListPage;

BodyTextListPage.propTypes = {
  input: PropTypes.object.isRequired,
};
