import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Item = styled.div`
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: #FFFFFF;
  
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
  
  
  p, h3{
    //margin-left: 2rem;
    
  // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //   margin-left: 0;
  //   text-align: center;
  // }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-left: 0;
    text-align: center;
  }

  }
  
  h3{
    //margin-bottom: 0.6rem;
    padding: 0.6rem 0 0;
    color: ${props => props.theme.colors.primary};
  }
  
  // &:nth-child(even){
  //   flex-direction: row-reverse;
  //   p, h3{
  //     margin-left: 0;
  //     margin-right: 2rem;
  //    
  //     // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //     //   margin-left: 0;
  //     //   text-align: center;
  //     // }
  //     @media (max-width: ${props => props.theme.breakpoints.s}) {
  //       margin-right: 0;
  //       text-align: center;
  //     }      
  //   }
  // }
    
  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    width: 200px;
    height: 200px; 
    margin: 0 auto;
  }
  
  img{
    max-width: 200px;
    max-height: 200px;
    display: inline-block;
    border-radius: 50%;
    // border: 2px solid ${props => props.theme.colors.beige};
  }
`
const Pict = styled.div`

  display: flex;
  flex-direction: column;
  flex: 1 0 40%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: auto;
    margin-left: auto;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: auto;
    margin: 0 auto 1rem 0;
    text-align: center;
  }
`
const Infos = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 60%;
`

export default class ListItemDomain extends Component {
  render() {
    const { node } = this.props
    return (
      <Item>
        <Pict>
          <Img fluid={node.data.image.localFile.childImageSharp.fluid} alt={node.data.title.text}/>
        </Pict>
        <Infos>
          <h3>{node.data.title.text}</h3>
          <div dangerouslySetInnerHTML={{ __html: node.data.description_du_domaine.html }} />
        </Infos>
      </Item>
    )
  }
}

ListItemDomain.propTypes = {
  node: PropTypes.object.isRequired,
}
