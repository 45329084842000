import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FlexWrapper, SectionPadding, Content, ListControl } from './Elements';
import ListingAgency from "../../components/ListingAgency";
import { Section, Title } from "../../components";

function BodyAgencyListPage({ input, agencies }) {
  // function HaveMore() {
  //   if (input.primary.more !== 'no') {
  //     return (
  //       <ListControl>
  //         <Link to="/blog">
  //           En voir plus
  //         </Link>
  //       </ListControl>
  //     );
  //   }
  //   return null;
  // }
  return (
    <Section style={{paddingTop: '2rem'}}>
      <FlexWrapper>
        <Title title={input.primary.title.html} />
        <Content dangerouslySetInnerHTML={{ __html: input.primary.intro.html }} />
        <ListingAgency agencies={agencies.edges} display={input.primary.display}/>
        {/*<HaveMore />*/}
      </FlexWrapper>
    </Section>
  );
}

export default BodyAgencyListPage;

BodyAgencyListPage.propTypes = {
  input: PropTypes.object.isRequired,
  agencies: PropTypes.object.isRequired,
};
