import React from 'react';
import PropTypes from 'prop-types';
import { MdArrowForward } from 'react-icons/md/';
import { FlexWrapper, SectionPadding, Content } from './Elements';
import GridList from '../../components/GridList';
import { Title, Wrapper, Section } from "../../components";

function BodyGridPage({ input }) {
  function HaveTitle() {
    if (input.primary.title !== null) {
      return <Title title={input.primary.title.html} color={input.primary.bgcolor} />;
    }
    return null;
  }
  return (
    <Section bgcolor={input.primary.bgcolor}>
      <Wrapper>
        <HaveTitle />
        <GridList items={input.items} key={input.items.title} columns={input.primary.columns}/>
      </Wrapper>
    </Section>
  );
}

export default BodyGridPage;

BodyGridPage.propTypes = {
  input: PropTypes.object.isRequired,
};
