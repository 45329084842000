import styled from '@emotion/styled'

const Section = styled.div`
  width: 100%;
  padding: 3rem 0 1rem;
    background-color: ${props => (props.theme.colors.white)};
    background-color: ${props => (props.bgcolor === "beige" ? props.theme.colors.lightbeige : '')};
    background-color: ${props => (props.bgcolor === "turquoise" ? props.theme.colors.primary : '')};
    background-color: ${props => (props.bgcolor === "bleu" ? props.theme.colors.footer : '')};
  // color: ${props => (props.bgcolor === "beige" ? props.theme.colors.white : props.theme.colors.text)};
  // h1,h2,h3,h4{
  // color: ${props => (props.bgcolor === "beige" ? props.theme.colors.white : props.theme.colors.text)};
  // }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 1.5rem;
  }
    @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 1.5rem;
  }
`

export default Section
