import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ListDocumentItem from './ListDocumentItem';

const List = styled.div`
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  list-style-type: none;
  margin-left: 0;
  display: flex;
  justify-content: space-around;
`;

export default class ListDocument extends Component {
  render() {
    const { documents } = this.props;
    return (
      <List>
        {documents.map(document => (
          <ListDocumentItem key={document.selected_docs.id} node={document.selected_docs.document[0]} />
        ))}
      </List>
    );
  }
}

ListDocument.propTypes = {
  documents: PropTypes.array.isRequired,
};
