import React from 'react';
import PropTypes from 'prop-types';
import { FlexWrapper, SectionPadding, Content } from './Elements';
import Subnav from '../../components/Subnav';

function BodySubnavtPage({ input }) {
  return (
    <SectionPadding>
      <FlexWrapper>
        {/* <Content dangerouslySetInnerHTML={{ __html: input.primary.title.html }}/> */}
        <Content dangerouslySetInnerHTML={{ __html: input.primary.subnav.document[0].data.title.html }} />
      </FlexWrapper>
      <FlexWrapper>
        <Subnav subnav={input.primary.subnav.document[0].data.subnav} />
      </FlexWrapper>
    </SectionPadding>
  );
}

export default BodySubnavtPage;

BodySubnavtPage.propTypes = {
  input: PropTypes.object.isRequired,
};
