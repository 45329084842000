import styled from '@emotion/styled'
import Wrapper from '../../components/Wrapper'
import Section from '../../components/Section'

export const FlexWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
`

export const Content = styled.div`
  padding-top: 1rem;
  .center-align{
  display: block;
    text-align: center;
  }
`

export const SectionPadding = styled(Section)`
  padding-top: 0rem;
  padding-bottom: 0rem;
`
