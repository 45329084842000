import styled from '@emotion/styled';
import Wrapper from '../../components/Wrapper';

export const Section = styled.section`
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 0;
`;

export const StyledBg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  .gatsby-image-outer-wrapper,
  .gatsby-image-wrapper {
    height: 100%;
  }
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const FlexWrapper = styled(Wrapper)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => (props.direction === 'right' ? 'row-reverse' : '')};
  z-index: 1;
`;

export const Aligner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Content = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
  width: 100%;
  z-index: 2;
  text-align: left;
  color: ${props => (props.bannerTheme === 'light' ? '#000000' : '#FFFFFF')};
  text-align: ${props => (props.align === 'center' ? 'center' : '')};
  text-align: ${props => (props.align === 'right' ? 'right' : '')};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => (props.bannerTheme === 'light' ? '#000000' : '#FFFFFF')};
  }

  h2 {
    font-size: 2.8rem;
  }

  a {
    display: inline-block;
    padding: 1rem 2.2rem;
    margin-top: 1rem;
    border: none;
    border-radius: 4px;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.black};
    transition: all 0.5s;

    &:hover {
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.primary};
    }
  }
`;
