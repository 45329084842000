import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ListItemChiffres from './ListItemChiffres';

const List = styled.div`
width: 100%;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  list-style-type: none;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  //flex-grow: 6;
`;

export default class ListingChiffres extends Component {
  render() {
    const { chiffres } = this.props;
    return (
      <List>
        {chiffres.map(chiffre => (
          <ListItemChiffres key={chiffre.name} node={chiffre} />
        ))}
      </List>
    );
  }
}

ListingChiffres.propTypes = {
  chiffres: PropTypes.array.isRequired,
};
