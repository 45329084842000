import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ListTexteImageItem from './ListTexteImageItem';

const List = styled.div`
  margin: 0.4rem auto;
  list-style-type: none;

`;

export default class ListTexteImage extends Component {
  render() {
    const { textes } = this.props;
    return (
      <List>
        {textes.map(texte => (
          <ListTexteImageItem key={texte.texte.text} node={texte} />
        ))}
      </List>
    );
  }
}

ListTexteImage.propTypes = {
  textes: PropTypes.array.isRequired,
};
