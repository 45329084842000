import React from 'react';
import PropTypes from 'prop-types';
import { FlexWrapper, SectionPadding, Content, StyledSection } from './Elements';
import ListingOffre from "../../components/Listoffre";
import ListingListLienOffre from "../../components/ListLienOffre/";
import { Title, Section } from "../../components";
import ListingChiffres from "../../components/ListChiffres";

function BodyChiffresPage({ input }) {

  function HaveTitle() {
    if (input.primary.title.html !== null) {
      return (
        <Title title={input.primary.title.html} />
      );
    }
    return null;
  }

  return (
    <StyledSection>
      <FlexWrapper>
        <HaveTitle />
      </FlexWrapper>
      <FlexWrapper>
        <ListingChiffres chiffres={input.items} />
      </FlexWrapper>
    </StyledSection>
  );
}

export default BodyChiffresPage;

BodyChiffresPage.propTypes = {
  input: PropTypes.object.isRequired,
};
