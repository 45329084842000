import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { FlexWrapper, SectionPadding, Content, ListControl } from './Elements'
import  ListingLast  from '../../components/ListingLast'
import { Title } from "../../components";

function BodyBlogLastPage({ input, last }) {
  function HaveMore() {
    if (input.primary.more !== 'no') {
      return (
        <ListControl>
          <Link to="/blog">Voir tous les articles</Link>
        </ListControl>
      )
    }
    return null
  }
  return (
    <SectionPadding bgcolor={input.primary.bgcolor} style={{paddingBottom: 0, marginBottom: 0}}>
      <FlexWrapper>
        <Title title={input.primary.title.html} color={input.primary.bgcolor}/>
        <ListingLast last={last.edges} />
        <HaveMore />
      </FlexWrapper>
    </SectionPadding>
  )
}

export default BodyBlogLastPage

BodyBlogLastPage.propTypes = {
  input: PropTypes.object.isRequired,
  last: PropTypes.object.isRequired,
}
