import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Link } from "gatsby";
import TruncateMarkup from "react-truncate-markup";

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.greyDark};
  font-style: normal;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.777rem;
  }
`;
const Item = styled.div`
  padding: 20px 0 0 0;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.03);
  margin-bottom: 0.6rem;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.2);
    transform: translateY(-10px);
  }

  p {
    min-height: 78px;
  }
  
  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    width: 200px;
    height: auto; 
    margin: 0 auto;
  }
  
  img{
    width: 200px;
    height: 200px;
    display: inline-block;
    // border: 2px solid ${props => props.theme.colors.beige};
  }
`
const Pict = styled.div`
display: block;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: auto;
    margin-left: auto;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: auto;
    margin: 0 auto 1rem 0;
    text-align: center;
  }
`

const Col = styled.div`
  margin-bottom: 2rem;
  position: relative;
  z-index: 100;
  flex-basis: calc(99.9% * 1 / 4 - 1.5rem);
  max-width: calc(99.9% * 1 / 4 - 1.5rem);
  width: calc(99.9% * 1 / 4 - 1.5rem);
  text-align: ${props => (props.center ? 'center' : '')};
  text-align: ${props => (props.right ? 'right' : '')};

  @media (max-width: 1000px) {
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    max-width: calc(99.9% * 1 / 2 - 1rem);
    width: calc(99.9% * 1 / 2 - 1rem);
  }
  @media (max-width: 700px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
`;

const StyledTitle = styled.div`
  min-height: 3.7rem;

  h2 {
    color: ${props => props.theme.colors.primary};
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.7rem;
    margin-bottom: 0.6rem;
    text-align: center;
    
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      font-size: 1.2rem!important;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1rem;
  }
`;

const ItemWrapper = styled.article`
  padding: 1rem;
  font-size: 0.9rem;
  font-weight: normal;
`;

export default class ListItemSecteur extends Component {
  render() {
    const { node } = this.props
    return (
      <Col>
        <StyledLink to={`${/secteur/ + node.uid}`}>
        <Item>
          <Pict>
            <Img fluid={node.data.thumb.localFile.childImageSharp.fluid} />
          </Pict>
          <ItemWrapper>
            <StyledTitle>
              <TruncateMarkup lines={2}>
                <h2>{node.data.secteur_d_activite.text}</h2>
              </TruncateMarkup>
            </StyledTitle>
            {/*<TruncateMarkup lines={3}>*/}
            {/*  /!*<p>{node.data.shortdesc.text}</p>*!/*/}
            {/*</TruncateMarkup>*/}
          </ItemWrapper>

        </Item>
        </StyledLink>
      </Col>
    )
  }
}

ListItemSecteur.propTypes = {
  node: PropTypes.object.isRequired,
}
