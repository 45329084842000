import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Section, FlexWrapper, Aligner, Content } from './Elements';
import { Title } from "../../components";

function BodyImgBannerPage({ input }) {
  function HaveLink() {
    if (input.primary.link !== null) {
      return (
        <span className="button">
          <a rel="noopener noreferrer" target={input.primary.link.target} href={input.primary.link.url}>
            {input.primary.label !== '' ? input.primary.label : 'Voir la suite'}
          </a>
        </span>
      );
    }
    return null;
  }

  return (
    <Section style={{backgroundColor: '#F8F8F8'}}>
        <FlexWrapper>
          <Aligner>
            <Content bannerTheme={input.primary.theme} align={input.primary.align}>
              <Title title={input.primary.bannertitle.html} />
              <Img style={{marginBottom: '1rem'}} fluid={input.primary.image.localFile.childImageSharp.fluid} />
              {/*<div dangerouslySetInnerHTML={{ __html: input.primary.text.html }} />*/}
              <HaveLink />
            </Content>
          </Aligner>
        </FlexWrapper>
    </Section>
  );
}
export default BodyImgBannerPage;

BodyImgBannerPage.propTypes = {
  input: PropTypes.object.isRequired,
};
