import styled from '@emotion/styled';
import Wrapper from '../../components/Wrapper';

export const FlexWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
`;

export const Content = styled.div`
  padding-top: 1rem;
`;

export const ListControl = styled.div`
  text-align: right;

    a {
    width: fit-content;
    padding-left: 1.9rem;
    justify-items: left;
    font-size: 0.9rem;
    color: ${props => props.theme.colors.greyDark};
    text-transform: uppercase;
    position: relative;

    span {
      font-size: 0.9rem;
      font-weight: 600;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-55%);
      transition: all 0.3s;
    }

    &:hover {
      span {
        color: ${props => props.theme.colors.primary};
        left: 10px;
      }
    }
  }
`;
