import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Img from 'gatsby-image';

const Item = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-shrink: 0;
  //align-items: start;
   flex-basis: 20%;
   align-content: center;
   flex-direction: column;
  
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    //display: block;
    flex-basis: 33%;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    //display: block;
    flex-basis: 45%;
  }

  .gatsby-image-outer-wrapper,
  .gatsby-image-wrapper {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    //opacity: 0.5;
    //transition: all 0.5s;

    img {
      max-width: 120px;
      max-height: 120px;
      display: inline-block;
      //opacity: 0.5;
      //filter: grayscale(1);
    }

    //&:hover {
    //  opacity: 1;
    //  img {
    //    filter: grayscale(0);
    //  }
    //}
  }
`;

const Pict = styled.div`
  display: inline-block;
  margin: 0 auto;
`;
const Infos = styled.div``;

export default class ListItemPartner extends Component {
  render() {
    const { node } = this.props;
    // const { name } = node.data;
    return (
      <Item>
        <Pict>
          <Img fluid={node.data.logo.localFile.childImageSharp.fluid} alt={node.data.logo.alt} />
        </Pict>
      </Item>
    );
  }
}

ListItemPartner.propTypes = {
  node: PropTypes.object.isRequired,
};
