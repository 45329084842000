import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Img from 'gatsby-image';

const Item = styled.div`
  background-color: ${props => props.theme.colors.white};
  margin: 0.6rem;
  transition: all 0.3s;
  text-align: center;
  

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.colors.primary};
    font-weight: 700;
    font-size: 1.0rem;
    line-height: 1.7rem;
    margin-bottom: 0.6rem;

    &.blue {
      color: ${props => props.theme.colors.primary};
    }
    @media (max-width: 600px) {
    font-size: 1.6rem !important;
    }
  }

  p {
    width: auto;
    min-height: 100px;
    margin-top: 1rem;
  }

  a {
    width: fit-content;
    padding-left: 1.9rem;
    justify-items: left;
    font-size: 0.9rem;
    color: ${props => props.theme.colors.greyDark};
    text-transform: uppercase;
    position: relative;

    span {
      font-size: 0.9rem;
      font-weight: 600;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-55%);
      transition: all 0.3s;
    }

    &:hover {
      span {
        color: ${props => props.theme.colors.primary};
        left: 10px;
      }
    }
  }
`;

const LinkWrapper = styled.div`
  text-align: center;
`;

const ImgWrapper = styled.div`
  max-width: 72%;
  margin: 0 auto 1rem auto;
  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    overflow: hidden; 
    margin: 0 auto 0.4rem;
    
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      width: 100%;
      height: auto;
    }
  }
`;

const Col = styled.div`
  margin-bottom: 2rem;
  position: relative;
  z-index: 100;
  flex-basis: calc(99.9% * 1 / 3 - 1.5rem);
  max-width: calc(99.9% * 1 / 3 - 1.5rem);
  width: calc(99.9% * 1 / 3 - 1.5rem);
  text-align: ${props => (props.center ? 'center' : '')};
  text-align: ${props => (props.right ? 'right' : '')};

  @media (max-width: 1000px) {
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    max-width: calc(99.9% * 1 / 2 - 1rem);
    width: calc(99.9% * 1 / 2 - 1rem);
  }
  @media (max-width: 700px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
`;

const Col2 = styled.div`
  margin-bottom: 2rem;
  position: relative;
  z-index: 100;
  flex-basis: calc(99.9% * 1 / 2 - 2.5rem);
  max-width: calc(99.9% * 1 / 2 - 2.5rem);
  width: calc(99.9% * 1 / 2 - 2.5rem);
  text-align: ${props => (props.center ? 'center' : '')};
  text-align: ${props => (props.right ? 'right' : '')};

  @media (max-width: 1000px) {
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    max-width: calc(99.9% * 1 / 2 - 1rem);
    width: calc(99.9% * 1 / 2 - 1rem);
  }
  @media (max-width: 700px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
`;

export default class GridListItem extends Component {
  render() {
    const { node, columns } = this.props;

    function HaveLink() {
      if (node.link !== null) {
        return (
          <a target={node.link.target} href={node.link.url}>
            <span>
              <MdArrowForward />
            </span>
            {node.label !== null ? node.label : 'Voir la suite'}
          </a>
        );
      }
      return null;
    }
    function ColumnsSet() {
      if (columns === '3') {
        return (
          <Col>
            <Item>
              <ImgWrapper>
                <Img fluid={node.image.localFile.childImageSharp.fluid} />
              </ImgWrapper>
              <div dangerouslySetInnerHTML={{ __html: node.title.html }} />
              <div dangerouslySetInnerHTML={{ __html: node.text.html }} />
              <LinkWrapper>
                <HaveLink />
              </LinkWrapper>
            </Item>
          </Col>
        );
      }
      return (
        <Col2>
          <Item>
            <ImgWrapper>
              <Img fluid={node.image.localFile.childImageSharp.fluid} />
            </ImgWrapper>
            <div dangerouslySetInnerHTML={{ __html: node.title.html }} />
            <div dangerouslySetInnerHTML={{ __html: node.text.html }} />
            <LinkWrapper>
              <HaveLink />
            </LinkWrapper>
          </Item>
        </Col2>
      );
    }

    return (
      <ColumnsSet />
    );
  }
}

GridListItem.propTypes = {
  node: PropTypes.object.isRequired,
  columns: PropTypes.string.isRequired,
};
