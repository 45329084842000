import styled from '@emotion/styled';
import Wrapper from '../../components/Wrapper';

export const Col = styled.div`
  position: relative;
  z-index: 100;
  flex-basis: calc(99.9% * 1 / 2 - 1.5rem);
  max-width: calc(99.9% * 1 / 2 - 1.5rem);
  width: calc(99.9% * 1 / 2 - 1.5rem);
  text-align: ${props => (props.center ? 'center' : '')};
  text-align: ${props => (props.right ? 'right' : '')};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: 1rem;
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    max-width: calc(99.9% * 1 / 2 - 1rem);
    width: calc(99.9% * 1 / 2 - 1rem);
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-bottom: 1rem;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    text-align: center;
    display: ${props => (props.noMobile ? 'none' : '')};
  }
`;

export const FlexWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${props => (props.direction === 'droite' ? 'row-reverse' : '')};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }

  .blue {
    color: ${props => props.theme.colors.primary};
  }
`;

export const ContactSocial = styled.div`
  font-size: 4rem;
  text-align: center;
  padding: 1.6rem 0;
  a {
    margin: 1rem;
    color: ${props => props.theme.colors.greyLight};
    transition: all 0.5s;

    &:hover {
      &.twitter {
        color: ${props => props.theme.colors.twitter};
      }
      &.linkedin {
        color: ${props => props.theme.colors.linkedin};
      }
    }
  }
`;
