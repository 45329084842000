import React from 'react';
import PropTypes from 'prop-types';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md/';
import { StyledPagination, StyledCount, StyledLink, StyledBlank } from './Elements';

const PreviousBtn = ({ humanPageNumber, previousPagePath }) => {
  const isFirstPage = humanPageNumber === 1;
  if (isFirstPage) {
    return <StyledBlank>t</StyledBlank>;
  }
  return (
    <StyledLink className="left" cover to={previousPagePath}>
      <span>
        <MdChevronLeft />
      </span>{' '}
      Précédent
    </StyledLink>
  );
};

const NextBtn = ({ humanPageNumber, nextPagePath, numberOfPages }) => {
  const isLastPage = humanPageNumber === numberOfPages;
  if (isLastPage) {
    return <StyledBlank>t</StyledBlank>;
  }
  return (
    <StyledLink className="right" cover direction="right" to={nextPagePath}>
      Suivant{' '}
      <span>
        <MdChevronRight />
      </span>
    </StyledLink>
  );
};

const NoMore = ({ humanPageNumber, numberOfPages }) => {
  const isLastPage = humanPageNumber === numberOfPages;
  if (isLastPage) {
    return <div>No more fun for the moment</div>;
  }
  return null;
};

const PagesLinks = ({ humanPageNumber, numberOfPages }) => {
  const isActive = humanPageNumber;
  return (
    <StyledCount>
      {isActive} / {numberOfPages}
    </StyledCount>
  );
};

const Pagination = ({ pageContext }) => (
  <>
    {/*<NoMore {...pageContext} />*/}
    <StyledPagination>
      <PreviousBtn {...pageContext} />
      <PagesLinks {...pageContext} />
      <NextBtn {...pageContext} />
    </StyledPagination>
  </>
);

Pagination.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Pagination;
