import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import Wrapper from '../../components/Wrapper';
import { theme } from '../../styles';

const Section = styled.section`
  width: 100%;
  margin: 0;
  background-color: ${props => props.theme.colors.greyLight};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 0;

  @media (max-width: ${theme.breakpoints.m}) {
    margin: 0;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    margin: 0;
  }
`;

const StyledBg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  .gatsby-image-outer-wrapper,
  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const FlexWrapper = styled(Wrapper)`
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-direction: ${props => (props.orientation === 'right' ? 'row-reverse' : '')};
  z-index: 1;

  @media (max-width: ${theme.breakpoints.m}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
`;

const Aligner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  background-color: rgba(255,255,255,0.55);
  padding: 3rem 0.8rem;
`;

const Content = styled.div`
  z-index: 2;
  text-align: center;
  color: ${props => (props.heroTheme === 'light' ? '#000000' : props.theme.colors.footer)};
  text-align: ${props => (props.align === 'center' ? 'center' : '')};
  text-align: ${props => (props.align === 'right' ? 'right' : '')};

  p {
    max-width: 650px;
    display: inline-block;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2 {
    color: ${props => (props.heroTheme === 'light' ? '#000000' : props.theme.colors.footer)};
    //text-transform: uppercase;
    margin-bottom: 0;
  }

  h1 {
    font-size: 3.4rem;
    @media (max-width: ${theme.breakpoints.s}) {
      font-size: 1.8rem !important;
    }
  }

  h2 {
    font-size: 1.6rem;
    @media (max-width: ${theme.breakpoints.s}) {
      font-size: 1.2rem !important;
    }
  }
`;

function BodyHeroBlog({ input }) {
  function HaveBG() {
    if (input.primary.bgimg.url !== null) {
      return (
        <StyledBg>
          <Img fluid={input.primary.bgimg.localFile.childImageSharp.fluid} />
        </StyledBg>
      );
    }
    return null;
  }
  const backgroundColor = { backgroundColor: input.primary.bgcolor };
  return (
    <Section style={backgroundColor}>
      {/*<Overlay>*/}
        <HaveBG />
        <FlexWrapper>
          <Aligner>
            <Content
              dangerouslySetInnerHTML={{ __html: input.primary.maintitle.html }}
              align={input.primary.orientation}
              heroTheme={input.primary.theme}
            />
            {/*<Content*/}
            {/*  dangerouslySetInnerHTML={{ __html: input.primary.subtitle.html }}*/}
            {/*  align={input.primary.orientation}*/}
            {/*  heroTheme={input.primary.theme}*/}
            {/*/>*/}
            {/*<Content*/}
            {/*  dangerouslySetInnerHTML={{ __html: input.primary.introduction.html }}*/}
            {/*  align={input.primary.orientation}*/}
            {/*  heroTheme={input.primary.theme}*/}
            {/*/>{' '}*/}
          </Aligner>
        </FlexWrapper>
      {/*</Overlay>*/}
    </Section>
  );
}
export default BodyHeroBlog;

BodyHeroBlog.propTypes = {
  input: PropTypes.object.isRequired,
};
