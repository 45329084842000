import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ListingJobItem } from '.'

const List = styled.div`
  margin-top: 1rem;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; ///todo remove when 3
  //justify-content: flex-start;
`

export default class ListingJob extends Component {
  render() {
    const { jobs } = this.props
    return (
      <List>
        {jobs.map(job => {
          //
          return (
            <ListingJobItem
              key={job.node.uid}
              node={job.node}
            />
          )
        })}
      </List>
    )
  }
}

ListingJob.propTypes = {
  jobs: PropTypes.array.isRequired,
}
