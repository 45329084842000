import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TruncateMarkup from 'react-truncate-markup'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/fr'

const Item = styled.article`
  width: auto;
  display: block;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.03);
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.1);
  }

  p {
    min-height: 104px;
  }
`

const ItemWrapper = styled.article`
  padding: 1rem;
  font-size: 1rem;
  font-weight: normal;
`

const Headline = styled.div`
  min-height: 40px;
  margin-bottom: 0.6rem;
  font-family: 'Muli', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.grey};
  font-size: 0.8rem;

  &:first-letter {
    text-transform: uppercase;
  }

  a {
    color: ${props => props.theme.colors.grey};
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
  }
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.black};
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 46%; /* explanation below */
  margin-top: 0;
  margin-bottom: 1rem;

  &:first-child {
    margin-right: 1.2rem;
  }
  &:last-child {
    margin-left: 1.2rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
    font-size: 1.777rem;
  &:first-child {
    margin-right: 0;
  }
  &:last-child {
    margin-left: 0;
  }
  }

  &:hover {
    text-decoration: none;
  }
`

const StyledTitle = styled.div`
  min-height: 4.4rem;

  h2 {
    color: ${props => props.theme.colors.footer};
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-bottom: 0.6rem;
    
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 1.1rem!important;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1rem;
  }
`
const StyledLinkCat = styled(Link)`
  display: inline-block;
  padding: 0 0 0.2rem 0;
  margin-bottom: 0.8rem;
  color: ${props => props.theme.colors.black};
  font-style: normal;
  font-size: 0.8rem;
  position: relative;

  &:before {
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s;
  }

  &.default {
    color: ${props => props.theme.colors.primary};
    &:before {
      background-color: ${props => props.theme.colors.primary};
    }
  }

  &.vert {
    color: ${props => props.theme.colors.yggVert};
    &:before {
      background-color: ${props => props.theme.colors.yggVert};
    }
  }

  &.bleuClair {
    color: ${props => props.theme.colors.yggBleuClair};
    &:before {
      background-color: ${props => props.theme.colors.yggBleuClair};
    }
  }

  &.bleu {
    color: ${props => props.theme.colors.yggBleu};
    &:before {
      background-color: ${props => props.theme.colors.yggBleu};
    }
  }

  &.or {
    color: ${props => props.theme.colors.yggOr};
    &:before {
      background-color: ${props => props.theme.colors.yggOr};
    }
  }

  &.violet {
    color: ${props => props.theme.colors.primary};
    &:before {
      background-color: ${props => props.theme.colors.primary};
    }
  }

  &.bronze {
    color: ${props => props.theme.colors.yggBronze};
    &:before {
      background-color: ${props => props.theme.colors.yggBronze};
    }
  }
  &.cuivre {
    color: ${props => props.theme.colors.yggCuivre};
    &:before {
      background-color: ${props => props.theme.colors.yggCuivre};
    }
  }
  &.magenta {
    color: ${props => props.theme.colors.yggMagenta};
    &:before {
      background-color: ${props => props.theme.colors.yggMagenta};
    }
  }

  &.turquoise {
    color: ${props => props.theme.colors.yggTurquoise};
    &:before {
      background-color: ${props => props.theme.colors.yggTurquoise};
    }
  }
  &.rouge {
    color: ${props => props.theme.colors.yggRouge};
    &:before {
      background-color: ${props => props.theme.colors.yggRouge};
    }
  }

  &:hover {
    text-decoration: none;
    &:before {
      width: 100%;
    }
  }
`

export default class ListLastItem extends Component {
  render() {
    const { node, authors, categories, color } = this.props
    // const dateToFormat = {node.data.date};
    return (
      // <Col>
      <StyledLink to={`${/blog/ + node.data.categorie.document[0].uid}/${node.uid}`}>
        <Item>
          <Img fluid={node.data.main.full.localFile.childImageSharp.fluid} />
          <ItemWrapper>
            <StyledTitle>
              <TruncateMarkup lines={2}>
                <h2>{node.data.title.text}</h2>
              </TruncateMarkup>
            </StyledTitle>
            <Headline>
              <Link to={`${/auteur/ + node.data.authors[0].author.uid}`}>{authors} </Link> il y a {''}
              <Moment locale="fr" fromNow ago>
                {node.first_publication_date}
              </Moment>
            </Headline>
            <TruncateMarkup lines={4}>
              <p>{node.data.shortdesc.text}</p>
            </TruncateMarkup>
            <StyledLinkCat className={color} to={`${/blog/ + node.data.categorie.document[0].uid}`}>
              # {categories}
            </StyledLinkCat>
          </ItemWrapper>
        </Item>
      </StyledLink>
      // </Col>
    )
  }
}

ListLastItem.propTypes = {
  node: PropTypes.object.isRequired,
  authors: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
}
