const theme = {
  colors: {
    primary: '#4EB6C0',
    bg: '#fff',
    black: '#525056',
    greyLight: '#EBEDF2',
    greyBlue: '#a2bce2',
    grey: '#595C62',
    greyDark: '#6E6E6E',
    greyDarker: '#3B4A51',
    footer: '#0A1331',
    linkedin: '#0077B5',
    twitter: '#0084b4',
    viadeo: '#f07355',
    white: '#FFFFFF',
    title: '#000000',
    lightbeige: '#F8F8F8'

  },
  maxWidth: '1048px',
  maxWidthText: '720px',
  breakpoints: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px',
  },
}

export default theme
