import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { prism } from '../styles';

const Content = styled.div`
  ${prism};
  margin: 0 auto 2rem auto;
  // max-width: ${props => props.theme.maxWidthText};
`;

const CodeBlock = ({ input }) => <Content dangerouslySetInnerHTML={{ __html: input.primary.code_block.html }} />;

export default CodeBlock;

CodeBlock.propTypes = {
  input: PropTypes.object.isRequired,
};
