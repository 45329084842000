import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Item = styled.div`
  margin-bottom: 1rem;
  //display: flex;
  flex-shrink: 0;
  //align-items: start;
   flex-basis: 25%;
  
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: block;
    flex-basis: 33%;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
    flex-basis: 100%;
  }
  
  
 
  // &:nth-child(even){
  //   flex-direction: row-reverse;
  //   p, h3{
  //     margin-left: 0;
  //     margin-right: 2rem;
  //    
  //     // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //     //   margin-left: 0;
  //     //   text-align: center;
  //     // }
  //     @media (max-width: ${props => props.theme.breakpoints.s}) {
  //       margin-right: 0;
  //       text-align: center;
  //     }      
  //   }
  // }
    
  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    width: 172px;
    height: 172px; 
    margin: 0 auto;
  }
  
  img{
    max-width: 172px;
    max-height: 172px;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.greyLight};
  }
`
const Pict = styled.div`
display: block;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: auto;
    margin-left: auto;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: auto;
    margin: 0 auto 1rem 0;
    text-align: center;
  }
`
const Infos = styled.div`
display: block;
  max-width: 136px;
  padding: 0.6rem 0;
  margin-right: auto;
  margin-left: auto;
  font-weight: bold;
  font-size: 0.85rem;
  text-align: center;
  //text-transform: uppercase;
  color: ${props => props.theme.colors.footer};
`

export default class ListItemOffre extends Component {
  render() {
    const { node } = this.props
    return (
      <Item>
        <Pict>
          <Img fluid={node.data.thumb.min.localFile.childImageSharp.fluid} />
        </Pict>
        <Infos>
          { node.data.title.text }
        </Infos>
      </Item>
    )
  }
}

ListItemOffre.propTypes = {
  node: PropTypes.object.isRequired,
}
