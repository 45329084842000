import React from 'react';
import PropTypes from 'prop-types';
import { FlexWrapper, SectionPadding, Content } from './Elements';
import ListingOffre from "../../components/Listoffre";
import ListingSecteur from "../../components/ListSecteur/";
import { Section, Title } from "../../components";

function BodySecteurListPage({ input }) {
  return (
    <Section bgcolor={input.primary.bgcolor}>
      <FlexWrapper>
        <Title  title={input.primary.title.html} color={input.primary.bgcolor} />
         {/*<Content dangerouslySetInnerHTML={{ __html: input.primary.title.html }}/>*/}
        {/*<Content dangerouslySetInnerHTML={{ __html: input.primary.selectedtext.html }} />*/}
      </FlexWrapper>
      <FlexWrapper>
        <ListingSecteur secteurs={input.items} />
      </FlexWrapper>
    </Section>
  );
}

export default BodySecteurListPage;

BodySecteurListPage.propTypes = {
  input: PropTypes.object.isRequired,
};
