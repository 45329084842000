import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import styled from '@emotion/styled';

const StyledForm = styled.form`
  width: 100%;
  padding: 2rem 8rem 0 8rem;
  background-color: white;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 2rem 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 2rem 0rem;
  }

  .right-align {
    text-align: right;
  }
`;
const StyledField = styled.div`
  width: 100%;
  padding: 0.2rem 0;
  margin-bottom: 1rem;

  input,
  select,
  textarea {
    width: 100%;
    background-color: transparent;
    transition: all 0.5s;

    &:focus {
      outline: none;
      border-color: ${props => props.theme.colors.primary};
    }

    &.is-nope {
      border-bottom-color: red;
    }
  }
  input {
    padding: 0.7rem 0;
    border: none;
    border-bottom: 1px solid ${props => props.theme.colors.greyDark};
  }

  select {
    margin-top: 0.3rem;
    padding: 0.7rem 0;
    height: 52px;
    border-color: ${props => props.theme.colors.greyDark};
  }

  textarea {
    resize: none;
    margin-top: 1rem;
    padding: 0.6rem 1rem;
    border: 1px solid ${props => props.theme.colors.greyDark};
  }

  input[type='checkbox'] {
    width: auto;
  }
`;

const StyledLabel = styled.div`
  width: 100%;
  padding: 0.2rem 0;
  font-weight: 600;
`;

const StyledError = styled.p`
  width: 100%;
  padding: 0.2rem 0;
  color: red;
`;
const StyledThanksMail = styled.div`
  width: 100%;
  padding: 2rem 8rem;
`;

const StyledButton = styled.button`
  padding: 1rem 2.2rem;
  margin-top: 1rem;
  border: none;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary};
  transition: all 0.3s;

  &:disabled {
    display: none;
  }

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.footer};
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  }
`;


// yup.setLocale({
//   mixed: {
//     default: 'Não é válido',
//     // eslint-disable-next-line no-template-curly-in-string
//     required: '${label} toto',
//     email: "${email} n'est pas valide",
//   },
//
//   // number: {ß
//   //   min: 'Deve ser maior que ${min}',
//   // },
// })

const schema = yup.object().shape({
  name: yup
    .string()
    .required('${label} est nécessaire.')
    .label('Le nom'),
  email: yup
    .string()
    .email('${label} Invalid email')
    .required('${label} Required')
    .label("L'adresse"),
  // age: yup
  //   .number()
  //   .required()
  //   .positive()
  //   .integer(),
});

// export default Form
export default function Form() {
  const { register, handleSubmit, watch, errors } = useForm({
    validationSchema: schema,
  });

  const compliance = watch('compliance');
  const [isHidden, setIsHidden] = useState(false);

  return (
    <>
      <StyledForm
        // onSubmit={fSubmit}
        noValidate
        name="contact"
        action="/success"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        hidden={isHidden}
      >
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <input type="hidden" name="contact" value="contact" />
        <StyledField>
          <StyledLabel>Nom</StyledLabel>
          <input type="text" placeholder="Nom" name="name" ref={register} />
          {errors.name && <p>{errors.name.message}</p>}
        </StyledField>
        <StyledField>
          <StyledLabel>Société - facultatif</StyledLabel>
          <input type="tel" placeholder="Société" name="company" ref={register} />
        </StyledField>
        <StyledField>
          <StyledLabel>Adresse de messagerie</StyledLabel>
          <input type="email" placeholder="Adresse de messagerie" name="email" ref={register} />
          {errors.email && <p>{errors.email.message}</p>}
        </StyledField>
        <StyledField>
          <StyledLabel>Téléphone - facultatif</StyledLabel>
          <input type="tel" placeholder="Téléphone" name="phone" ref={register} />
        </StyledField>
        <StyledField>
          <StyledLabel>Message</StyledLabel>
          <textarea rows="4" name="message" ref={register} />
        </StyledField>
        <StyledField>
          <input
            type="checkbox"
            name="compliance"
            value="En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de la demande de contact et de la relation commerciale qui peut en découler."
            ref={register({ required: true })}
          />{' '}
          <span>
            En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de la
            demande de contact et de la relation commerciale qui peut en découler.
          </span>
        </StyledField>
        <input type="hidden" name="form-name" value="contact" />
        <div className="right-align">
          {compliance && <StyledButton type="submit"> Envoyer le message</StyledButton>}
        </div>
      </StyledForm>
      <StyledThanksMail hidden={!isHidden}>
        <h3>Merci !</h3>
        <p>Votre message a bien éte envoyé.</p>
        <p>Nous vous répondrons dans les meilleurs délais.</p>
        <p>l’équipe de Confluence Conseil</p>
      </StyledThanksMail>
    </>
  );
}
