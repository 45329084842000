import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Wrapper, Section, Title } from '../../components'

const StyledLinkExt = styled.a`
  display: inline-block;
  padding: 1rem 2rem;
  margin-bottom: 0.8rem;
  color: ${props => props.theme.colors.text};
  font-style: normal;
  font-size: 1rem;
  position: relative;
  border: 1px solid;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  text-decoration: none;

  // &.default {
  //   color: ${props => props.theme.colors.primary};
  //   border-color: ${props => props.theme.colors.primary};
  //   &:hover {
  //     background-color: ${props => props.theme.colors.primary};
  //     color: ${props => props.theme.colors.white};
  //   }
  // }
  // &.vert {
  //   color: ${props => props.theme.colors.yggVert};
  //   border-color: ${props => props.theme.colors.yggVert};
  //   &:hover {
  //     background-color: ${props => props.theme.colors.yggVert};
  //     color: ${props => props.theme.colors.white};
  //   }
  // }
  // &.bleuClair {
  //   color: ${props => props.theme.colors.yggBleuClair};
  //   border-color: ${props => props.theme.colors.yggBleuClair};
  //   &:hover {
  //     background-color: ${props => props.theme.colors.yggBleuClair};
  //     color: ${props => props.theme.colors.white};
  //   }
  // }
  // &.bleu {
  //   color: ${props => props.theme.colors.yggBleu};
  //   border-color: ${props => props.theme.colors.yggBleu};
  //
  //   &:hover {
  //     color: ${props => props.theme.colors.white};
  //     background-color: ${props => props.theme.colors.yggBleu};
  //   }
  // }
  // &.or {
  //   color: ${props => props.theme.colors.yggOr};
  //   border-color: ${props => props.theme.colors.yggOr};
  //   &:hover {
  //     background-color: ${props => props.theme.colors.yggOr};
  //     color: ${props => props.theme.colors.white};
  //   }
  // }
  // &.violet {
  //   color: ${props => props.theme.colors.primary};
  //   border-color: ${props => props.theme.colors.primary};
  //   &:hover {
  //     background-color: ${props => props.theme.colors.primary};
  //     color: ${props => props.theme.colors.white};
  //   }
  // }
  // &.bronze {
  //   color: ${props => props.theme.colors.yggBronze};
  //   border-color: ${props => props.theme.colors.yggBronze};
  //   &:hover {
  //     background-color: ${props => props.theme.colors.yggBronze};
  //     color: ${props => props.theme.colors.white};
  //   }
  // }
  // &.cuivre {
  //   color: ${props => props.theme.colors.yggCuivre};
  //   border-color: ${props => props.theme.colors.yggCuivre};
  //   &:hover {
  //     background-color: ${props => props.theme.colors.yggCuivre};
  //     color: ${props => props.theme.colors.white};
  //   }
  // }
  // &.magenta {
  //   color: ${props => props.theme.colors.yggMagenta};
  //   border-color: ${props => props.theme.colors.yggMagenta};
  //   &:hover {
  //     background-color: ${props => props.theme.colors.yggMagenta};
  //     color: ${props => props.theme.colors.white};
  //   }
  // }
  // &.turquoise {
  //   color: ${props => props.theme.colors.yggTurquoise};
  //   border-color: ${props => props.theme.colors.yggTurquoise};
  //   &:hover {
  //     background-color: ${props => props.theme.colors.yggTurquoise};
  //     color: ${props => props.theme.colors.white};
  //   }
  // }
  // &.rouge {
  //   color: ${props => props.theme.colors.yggRouge};
  //   border-color: ${props => props.theme.colors.yggRouge};
  //   &:hover {
  //     background-color: ${props => props.theme.colors.yggRouge};
  //     color: ${props => props.theme.colors.white};
  //   }
  // }

  &:hover {
    text-decoration: none;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  }
`

const BodyBlogButon = ({ input }) => (
  <Section style={{ paddingTop: '1rem' }}>
    <Wrapper style={{ textAlign: 'center' }}>
      <StyledLinkExt
        // className={input.primary.color}
        rel="noopener noreferrer"
        href={input.primary.link.url}
        target={input.primary.link.target}
      >
        {input.primary.label}
      </StyledLinkExt>
    </Wrapper>
  </Section>
)
export default BodyBlogButon

BodyBlogButon.propTypes = {
  input: PropTypes.object.isRequired,
}
