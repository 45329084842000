import styled from '@emotion/styled'

export const HeroC = styled.div`
  background-color: ${props => props.theme.colors.greyLight};
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledBg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  
  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    height: 100%; 
  }
`;

export const HeroInner = styled.div`
  padding-top: 10rem;
  padding-bottom: 10rem;
  width: 100%;
  text-align: left;
  z-index: 1;
  color: ${props => props.theme.colors.white};


  
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`;

export const Aligner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  text-align: center;
  align-items: center;

  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    width: 100%;
    max-width: 500px; 
    height: 100%;
  }
  
`;


export const Content = styled.div`
  font-size: 1.0rem;
  font-weight: 600;
  padding: 1.0rem 0 0 0;
`;
