import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TruncateMarkup from 'react-truncate-markup'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/fr'

const Item = styled.article`
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.03);
  margin-bottom: 1.45rem;
  transition: all 0.3s;


  &:hover {
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.1);
  }

  p {
    min-height: 78px;
  }
`

const ItemWrapper = styled.article`
  padding: 1rem;
  font-size: 1rem;
  font-weight: normal;
`

const Headline = styled.div`
min-height: 40px;
  margin-bottom: 0.6rem;
  font-family: 'Muli', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.grey};
  font-size: 0.8rem;

  &:first-letter {
    text-transform: uppercase;
  }

  a {
    color: ${props => props.theme.colors.grey};
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
  }
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.black};
  font-style: normal;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.777rem;
  }
`

const StyledTitle = styled.div`
  min-height: 3.7rem;
  display: flex;
  align-items: center;

  h2 {
    color: ${props => props.theme.colors.footer};
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.7rem;
    margin-bottom: 0.6rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1rem;
  }
`
const StyledLinkCat = styled(Link)`
  display: inline-block;
  padding: 0 0 0.2rem 0;
  margin-bottom: 0.8rem;
  color: ${props => props.theme.colors.black};
  font-style: normal;
  font-size: 0.8rem;
  position: relative;

  &:before {
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s;
  }

  &.default {
    color: ${props => props.theme.colors.primary};
    &:before {
      background-color: ${props => props.theme.colors.primary};
    }
  }

  &.vert {
    color: ${props => props.theme.colors.yggVert};
    &:before {
      background-color: ${props => props.theme.colors.yggVert};
    }
  }

  &.bleuClair {
    color: ${props => props.theme.colors.yggBleuClair};
    &:before {
      background-color: ${props => props.theme.colors.yggBleuClair};
    }
  }

  &.bleu {
    color: ${props => props.theme.colors.yggBleu};
    &:before {
      background-color: ${props => props.theme.colors.yggBleu};
    }
  }

  &.or {
    color: ${props => props.theme.colors.yggOr};
    &:before {
      background-color: ${props => props.theme.colors.yggOr};
    }
  }

  &.violet {
    color: ${props => props.theme.colors.primary};
    &:before {
      background-color: ${props => props.theme.colors.primary};
    }
  }

  &.bronze {
    color: ${props => props.theme.colors.yggBronze};
    &:before {
      background-color: ${props => props.theme.colors.yggBronze};
    }
  }
  &.cuivre {
    color: ${props => props.theme.colors.yggCuivre};
    &:before {
      background-color: ${props => props.theme.colors.yggCuivre};
    }
  }
  &.magenta {
    color: ${props => props.theme.colors.yggMagenta};
    &:before {
      background-color: ${props => props.theme.colors.yggMagenta};
    }
  }

  &.turquoise {
    color: ${props => props.theme.colors.yggTurquoise};
    &:before {
      background-color: ${props => props.theme.colors.yggTurquoise};
    }
  }
  &.rouge {
    color: ${props => props.theme.colors.yggRouge};
    &:before {
      background-color: ${props => props.theme.colors.yggRouge};
    }
  }

  &:hover {
    text-decoration: none;
    &:before {
      width: 100%;
    }
  }
`
const Col = styled.div`
  margin-bottom: 2rem;
  //margin-right: 4rem; //todo remove when 3
  position: relative;
  z-index: 100;
  flex-basis: calc(99.9% * 1 / 3 - 1.3rem);
  max-width: calc(99.9% * 1 / 3 - 1.3rem);
  width: calc(99.9% * 1 / 3 - 1.3rem);
  text-align: ${props => (props.center ? 'center' : '')};
  text-align: ${props => (props.right ? 'right' : '')};

  @media (max-width: 1000px) {
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    max-width: calc(99.9% * 1 / 2 - 1rem);
    width: calc(99.9% * 1 / 2 - 1rem);
  }
  @media (max-width: 700px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
`

export default class ListingJobItem extends Component {
  render() {
    const { node } = this.props
    // const dateToFormat = {node.data.date};
    return (
      <Col>
        <StyledLink to={`${/carriere/ + node.uid}`}>
          <Item>
            <ItemWrapper>
              <StyledTitle>
                <TruncateMarkup lines={2}>
                  <h2>{node.data.title.text}</h2>
                </TruncateMarkup>
              </StyledTitle>
              <Headline>
                <span style={{fontWeight: "bold"}}>Date : </span>
                <Moment locale="fr" format="DD/MM/YYYY">
                  {node.data.date}
                </Moment>
                <div>
                  <span style={{fontWeight: "bold"}}>Lieu : </span>
                  {node.data.localisation}
                </div>
              </Headline>

              <TruncateMarkup lines={3}>
                <p>{node.data.shortdesc.text}</p>
              </TruncateMarkup>
            </ItemWrapper>
          </Item>
        </StyledLink>
      </Col>
    )
  }
}

ListingJobItem.propTypes = {
  node: PropTypes.object.isRequired,
}
