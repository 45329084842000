import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Item = styled.div`
  //min-height: 540px;
  max-width: 180px;
  padding: 0.6rem;
  //border-radius: 4px;
   background-color: ${props => props.theme.colors.white};
  //box-shadow: 0 5px 35px rgba(0, 0, 0, 0.03);
  margin-bottom: 1rem;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  flex: 1 0 20%;
  position: relative;
  align-items: center;
  justify-content: center;
  
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
  
  
  p, h3{
    //margin-left: 2rem;
    text-align: center;
    
  // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //   margin-left: 0;
  //   text-align: center;
  // }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-left: 0;
    text-align: center;
  }

  }
  
  h3{
    //margin-bottom: 0.6rem;
    padding: 0.6rem 0 0;
    color: ${props => props.theme.colors.primary};
    font-size: 1.2rem;
  }
  
  // &:nth-child(even){
  //   flex-direction: row-reverse;
  //   p, h3{
  //     margin-left: 0;
  //     margin-right: 2rem;
  //    
  //     // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //     //   margin-left: 0;
  //     //   text-align: center;
  //     // }
  //     @media (max-width: ${props => props.theme.breakpoints.s}) {
  //       margin-right: 0;
  //       text-align: center;
  //     }      
  //   }
  // }
    
  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    width: 160px;
    height: 160px; 
    margin: 0 auto;
  }
  
  img{
    max-width: 160px;
    max-height: 160px;
    display: inline-block;
    border-radius: 50%;
    // border: 2px solid ${props => props.theme.colors.beige};
  }
`
const Pict = styled.div`

  display: flex;
  flex-direction: column;
  flex: 1 0 40%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: auto;
    margin-left: auto;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: auto;
    margin: 0 auto 1rem 0;
    text-align: center;
  }
`
const Infos = styled.div`
  display: flex;
  text-align: center;
  align-content: center;
`

export default class ListItemDomainMinimalist extends Component {
  render() {
    const { node } = this.props
    return (
      <Item>
        <Pict>
          <Img fluid={node.data.image.localFile.childImageSharp.fluid} alt={node.data.title.text} />
        </Pict>
        <Infos>
          <h3>{node.data.title.text}</h3>
        </Infos>
      </Item>
    )
  }
}

ListItemDomainMinimalist.propTypes = {
  node: PropTypes.object.isRequired,
}
