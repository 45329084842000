import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  FacebookShareButton,
  TwitterShareButton,
  PocketShareButton,
  EmailShareButton,
  LinkedinShareButton
} from "react-share";
import { FaTwitter, FaFacebookF, FaGetPocket, FaRegPaperPlane, FaLinkedin } from 'react-icons/fa'
import { StyledShare, StyledShareItem } from './Elements'

export default class ShareVirality extends Component {
  render() {
    const { shareUrl } = this.props
    return (
      <StyledShare>
        <StyledShareItem className="linkedin">
          <LinkedinShareButton url={shareUrl}>
            <FaLinkedin />
          </LinkedinShareButton>
        </StyledShareItem>
        <StyledShareItem className="facebook">
          <FacebookShareButton url={shareUrl}>
            <FaFacebookF />
          </FacebookShareButton>
        </StyledShareItem>
        <StyledShareItem className="twitter">
          <TwitterShareButton url={shareUrl}>
            <FaTwitter />
          </TwitterShareButton>
        </StyledShareItem>
        <StyledShareItem className="pocket">
          <PocketShareButton url={shareUrl}>
            <FaGetPocket />
          </PocketShareButton>
        </StyledShareItem>
        <StyledShareItem className="mail">
          <EmailShareButton url={shareUrl}>
            <FaRegPaperPlane />
          </EmailShareButton>
        </StyledShareItem>
      </StyledShare>
    )
  }
}

ShareVirality.propTypes = {
  shareUrl: PropTypes.string.isRequired,
}
