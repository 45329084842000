import React from 'react';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';

const MenuItems = (props) => (

  <div>
    <ul>
      <li><Link activeClassName="active" onClick={props.onToggleMenu} to="/entreprise">Entreprise</Link></li>
      <li><Link activeClassName="active" onClick={props.onToggleMenu} to="/offre">Nos Offres</Link></li>
      <li><Link activeClassName="active" onClick={props.onToggleMenu} to="/references">Références</Link></li>
      <li><Link activeClassName="active" onClick={props.onToggleMenu} to="/blog">Blog</Link></li>
      <li><Link activeClassName="active" onClick={props.onToggleMenu} to="/contact">Contact</Link></li>
      <li><Link className="joinus" activeClassName="active" onClick={props.onToggleMenu} to="/carriere">Nous rejoindre</Link></li>
      <li><a className="intra" onClick={props.onToggleMenu} target="_blank" href="https://confluenceconseil.sharepoint.com/">Intranet</a></li>
    </ul>
  </div>

)

MenuItems.propTypes = {
  onToggleMenu: PropTypes.func
}

export default MenuItems
