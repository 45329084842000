import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { StyledInput, StyledSubmit, StyledForm, StyledThanks } from './Elements';

export default class Newsletter extends React.Component {
  state = {
    email: null,
    hidden: false,
  };

  _handleChange = e => {
    console.log({
      [`${e.target.name}`]: e.target.value,
    });
    this.setState({
      [`${e.target.name}`]: e.target.value,
    });
  };

  _handleSubmit = e => {
    e.preventDefault();

    console.log('submit', this.state);

    addToMailchimp(this.state.email)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`);

        if (result !== 'success') {
          throw msg;
        }
        this.setState({ hidden: true });

        // alert(msg)
      })
      .catch(err => {
        console.log('err', err);
        alert(err);
      });
  };

  render() {
    return (
      <>
        <StyledForm onSubmit={this._handleSubmit} hidden={this.state.hidden}>
          <StyledInput
            arial-label="email"
            id="email"
            type="email"
            onChange={this._handleChange}
            placeholder="Email"
            name="email"
          />
          <StyledSubmit type="submit" value="Je m'inscris !" />
        </StyledForm>
        <StyledThanks hidden={!this.state.hidden}>Merci</StyledThanks>
      </>
    );
  }
}
