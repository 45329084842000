import React from 'react';
import PropTypes from 'prop-types';
import { FlexWrapper,  Content } from './Elements';
import ListingProfile from '../../components/Listprofile';
import { Section, Title } from "../../components";

function BodyProfileListPage({ input }) {
  return (
    <Section style={{backgroundColor: '#F8F8F8'}}>
      <FlexWrapper>
        <Title title={input.primary.name.html} />
        <Content dangerouslySetInnerHTML={{ __html: input.primary.selectedtext.html }} />
        <ListingProfile profiles={input.items} />
      </FlexWrapper>
    </Section>
  );
}

export default BodyProfileListPage;

BodyProfileListPage.propTypes = {
  input: PropTypes.object.isRequired,
};
