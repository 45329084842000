import React from 'react';
import PropTypes from 'prop-types';
import { MdArrowForward } from 'react-icons/md/';
import { FlexWrapper, SectionPadding, Content } from './Elements';
import { ListingPress } from '../../components';

function BodyPressListPage({ input, press }) {
  return (
    <SectionPadding>
      <FlexWrapper>
        <Content dangerouslySetInnerHTML={{ __html: input.primary.title.html }} />
        <ListingPress press={press.edges} />
      </FlexWrapper>
    </SectionPadding>
  );
}

export default BodyPressListPage;

BodyPressListPage.propTypes = {
  input: PropTypes.object.isRequired,
  press: PropTypes.object.isRequired,
};
