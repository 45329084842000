import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ListCardItem } from '.'

const List = styled.div`
  margin-top: 1rem;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly; ///todo remove when 3
  //justify-content: flex-start;
`

export default class ListingCardSelected extends Component {
  render() {
    const { posts } = this.props
    return (
      <List>
        {posts.map(post => {
          const categories = post.articles.document[0].data.categorie.document[0].data.name
          const { color } = post.articles.document[0].data.categorie.document[0].data
          const authors = post.articles.document[0].data.authors.map(c => `${c.author.document[0].data.name}, `)
          //
          return (
            <ListCardItem
              key={post.articles.document[0].uid}
              node={post.articles.document[0]}
              categories={categories}
              authors={authors}
              color={color}
            />
          )
        })}
      </List>
    )
  }
}

ListingCardSelected.propTypes = {
  posts: PropTypes.array.isRequired,
}
