import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Content = styled.div`
  position: relative;
  padding-bottom: 35.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 0 auto 2rem auto;
  max-width: ${props => props.theme.maxWidthText};
`;

const Player = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Video = ({ input }) => (
  <Content>
    <Player dangerouslySetInnerHTML={{ __html: input.primary.video.html }} />
  </Content>
);

export default Video;

Video.propTypes = {
  input: PropTypes.object.isRequired,
};
