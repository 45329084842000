import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Wrapper, Section, Title } from '../../components';
import { MdArrowForward } from "../BodyArticlePage/BodyArticlePage";
import { Aligner, Col, FlexWrapper } from "../BodyArticlePage/Elements";
import Img from "gatsby-image";

const Content = styled.div`
  margin: 0 auto 1rem auto;
    // @media (max-width: ${props => props.theme.breakpoints.m}) {
    //   padding-left: 1rem;
    //   padding-right: 1rem;
    // }
  p{
    .center{
      display: block;
      text-align: center;
    }
    a{
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: fit-content;
    }
  }

  .button{
    text-align: center;
    display: block;
  }
    a{
      font-size: 0.7rem;
      line-height: 2.6rem;
      font-weight: bold;
      text-transform: uppercase;
      transition: all 0.5s;
      display: inline-flex;
      position: relative;
      padding-left: 13px;
      padding-right: 13px;
      border-radius: 3px;
      color: ${props => props.theme.colors.primary};
      border: 2px solid ${props => props.theme.colors.primary};
      transition: all 0.3s;
      margin: 0 auto 1rem auto;
      text-align: center;


        &:hover{
          color: ${props => props.theme.colors.white}!important;
          background-color: ${props => props.theme.colors.primary};
        }
  }

  .block-img {
    text-align: center;
    margin: 3rem 0;

    img {
      max-width: 90%;
    }
  }


  blockquote {
    font-size: 1.2rem;

    width: 80%;
    display: block;
    margin: 1.8rem auto;

    p {
      font-weight: bold;
      margin-bottom: 0;


    }

    p::before,
    p::after {
      content: '“';
      font-family: Georgia;
      font-size: 4rem;
      margin: -1.4rem 0 0 -3rem;
      position: absolute;
      opacity: 0.5;
    }

    p::after {
      content: '”';
      margin: -0.6rem -5rem 0 0;
    }

    cite {
      font-size: 1.5rem;
    }
  }
`;


function BodyTextPage({ input }) {
  function HaveTitle() {
    if (input.primary.title1.text !== null) {
      return (
        <Title  title={input.primary.title1.html} />
      );
    }
    return null;
  }
  function IsTurquoise() {
    if (input.primary.bgcolor !== "turquoise") {
      return (
        <Content dangerouslySetInnerHTML={{ __html: input.primary.text.html }} />
      );
    }
    return (<Content style={{color: 'white'}} dangerouslySetInnerHTML={{ __html: input.primary.text.html }} />);
  }

  return (
    <Section bgcolor={input.primary.bgcolor}>
      <Wrapper>
        <HaveTitle/>
        <IsTurquoise/>
        {/*<Content dangerouslySetInnerHTML={{ __html: input.primary.text.html }} />*/}
      </Wrapper>
    </Section>
  );
}

export default BodyTextPage;

BodyTextPage.propTypes = {
  input: PropTypes.object.isRequired,
};
