import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ListItemText from './ListItemText';

const List = styled.div`
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  padding: 1rem 0;
  list-style-type: none;
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
`;

export default class ListingText extends Component {
  render() {
    const { textes } = this.props;
    return (
      <List>
        {textes.map(texte => (
          <ListItemText key={texte.items} node={texte} />
        ))}
      </List>
    );
  }
}

ListingText.propTypes = {
  textes: PropTypes.array.isRequired,
};
