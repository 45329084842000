import styled from '@emotion/styled'
import Wrapper from '../../components/Wrapper'

export const FlexWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: ${props => (props.direction === 'droite' ? 'row-reverse' : '')};
  max-width: 988px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }

  .gatsby-image-wrapper {
    height: 250px !important;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
`

export const Content = styled.div`
  margin: 0.6rem 0;
  p {
    width: 100%;
  }
  blockquote{
  width: 100%;
    font-size: 0.85rem;
    margin: 0.6rem auto ;
  }
`

export const Aligner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  a{
    width: fit-content;
    justify-items: left;
    font-size: 0.9rem;
    color: ${props => props.theme.colors.greyDark};
    position: relative;
  
    &:hover{

    }
`

export const Col = styled.div`
  position: relative;
  z-index: 100;
  flex-basis: calc(99.9% * 1 / 2 - 1.5rem);
  max-width: calc(99.9% * 1 / 2 - 1.5rem);
  width: calc(99.9% * 1 / 2 - 1.5rem);
  text-align: ${props => (props.center ? 'center' : '')};
  text-align: ${props => (props.right ? 'right' : '')};
  .gatsby-image-outer-wrapper,
  .gatsby-image-wrapper {
    height: 100% !important;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: 1rem;
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    max-width: calc(99.9% * 1 / 2 - 1rem);
    width: calc(99.9% * 1 / 2 - 1rem);
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-bottom: 1rem;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    text-align: center;
    display: ${props => (props.noMobile ? 'none' : '')};
  }
`
