import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ListItemMoyen from './ListItemMoyen';

const List = styled.div`
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  list-style-type: none;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default class ListingMoyen extends Component {
  render() {
    const { domains } = this.props;
    return (
      <List>
        {domains.map(domain => (
          <ListItemMoyen key={domain.domaine.uid} node={domain.domaine.document[0]} />
        ))}
      </List>
    );
  }
}

ListingMoyen.propTypes = {
  domains: PropTypes.array.isRequired,
};
