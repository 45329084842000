import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TruncateMarkup from 'react-truncate-markup';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Logo from '../assets/logo.svg'

const Item = styled.article`
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.06);
  margin-bottom: 1.45rem;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.2);
    transform: translateY(-10px);
  }

  p {
    min-height: 78px;
  }
`;

const ItemWrapper = styled.article`
  padding: 1rem;
  font-size: 1rem;
  font-weight: normal;
`;

const Headline = styled.div`
  margin-bottom: 0.6rem;
  color: ${props => props.theme.colors.grey};
  font-size: 0.8rem;

  &:first-letter {
    text-transform: uppercase;
  }

  a {
    color: ${props => props.theme.colors.black};
    font-style: normal;
    font-weight: normal;
    transition: all 0.5s;

    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.greyDark};
  font-style: normal;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.777rem;
  }
`;

// const StyledLinkCat = styled(Link)`
//   display: inline-block;
//   padding: 0 0 0.2rem 0;
//   margin-bottom: 0.8rem;
//   color: ${props => props.theme.colors.black};
//   font-style: normal;
//   font-size: 0.8rem;
//   position: relative;
//
//   &:before {
//     content: '';
//     width: 100%;
//     height: 2px;
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     transform: translateX(-50%);
//     transition: all 0.5s;
//   }
//
//   &.default {
//     &:before {
//       background-color: ${props => props.theme.colors.primary};
//     }
//   }
//
//   &.hitRed {
//     &:before {
//       background-color: ${props => props.theme.colors.hitRed};
//     }
//   }
//
//   &.hitGreen {
//     &:before {
//       background-color: ${props => props.theme.colors.hitGreen};
//     }
//   }
//
//   &.hitOrange {
//     &:before {
//       background-color: ${props => props.theme.colors.hitOrange};
//     }
//   }
//
//   &.hitBondi {
//     &:before {
//       background-color: ${props => props.theme.colors.hitBondi};
//     }
//   }
//
//   &:hover {
//     &:before {
//       width: 0;
//     }
//     &.default {
//       &:before {
//         color: ${props => props.theme.colors.primary};
//       }
//     }
//
//     &.hitRed {
//       color: ${props => props.theme.colors.hitRed};
//     }
//
//     &.hitGreen {
//       color: ${props => props.theme.colors.hitGreen};
//     }
//
//     &.hitOrange {
//       color: ${props => props.theme.colors.hitOrange};
//     }
//
//     &.hitBondi {
//       color: ${props => props.theme.colors.hitBondi};
//     }
//   }
// `;

const StyledTitle = styled.div`
  //min-height: 3.7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: center;
  margin-bottom: 0.6rem;

  h2 {
    color: ${props => props.theme.colors.footer};
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.7rem;
    margin-bottom: 0.6rem;
    
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      font-size: 1.4rem!important;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1rem;
  }
`;

const Col = styled.div`
  margin-bottom: 2rem;
  position: relative;
  z-index: 100;
  flex-basis: calc(99.9% * 1 / 3 - 1.5rem);
  max-width: calc(99.9% * 1 / 3 - 1.5rem);
  width: calc(99.9% * 1 / 3 - 1.5rem);
  text-align: ${props => (props.center ? 'center' : '')};
  text-align: ${props => (props.right ? 'right' : '')};

  @media (max-width: 1000px) {
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    max-width: calc(99.9% * 1 / 2 - 1rem);
    width: calc(99.9% * 1 / 2 - 1rem);
  }
  @media (max-width: 700px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
`;

export default class ListAgencyItem extends Component {
  render() {
    const { node, display } = this.props;
    function DisplayMode() {
      if (display === 'full') {
        return (
          <>
            <div>{node.data.adresse_1}</div>
            <div>{node.data.cp} - {node.data.ville}</div>
            <div>Tél : {node.data.phone}</div>
          </>
        );
      }
      return null;
    }
    function IsNantes() {
      if (node.data.ville === 'Orvault') {
        return (
          <h2>Nantes</h2>
        );
      }
      return <h2>{node.data.ville}</h2>;
    }
    return (
      <Col>
        <StyledLink to={`${/agence/ + node.uid}`}>
          <Item>
            <Img
              fluid={node.data.image.thumb.localFile.childImageSharp.fluid}
              alt={node.data.name.text}
              title={node.data.name.text}
            />
            <ItemWrapper>
              <StyledTitle>
                <Logo style={{height: 45, marginLeft: -10}} />
                <TruncateMarkup lines={2}>
                   <IsNantes />
                </TruncateMarkup>
              </StyledTitle>
              <DisplayMode />
            </ItemWrapper>
          </Item>
        </StyledLink>
      </Col>
    );
  }
}

ListAgencyItem.propTypes = {
  node: PropTypes.object.isRequired,
  display: PropTypes.string.isRequired,
};
