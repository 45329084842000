import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ListPressItem from './ListPressItem'

const List = styled.ul`
  margin-top: 2rem;
  margin-bottom: 4rem;
  list-style-type: none;
  margin-left: 0;
`

export default class ListingPress extends Component {
  render() {
    const { press } = this.props
    return (
      <List>
        {press.map(pres => (
          <ListPressItem key={pres.node.uid} node={pres.node} />
        ))}
      </List>
    )
  }
}

ListingPress.propTypes = {
  press: PropTypes.array.isRequired,
}
