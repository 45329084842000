import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Section from '../components/Section';

const BlockQuote = styled.blockquote`
  max-width: ${props => props.theme.maxWidthText};
  font-size: 1.2rem;
  width: 80%;
  display: block;
  margin: 1.8rem auto;
  color: ${props => props.theme.colors.greyDark};

  p {
    font-style: italic;
    font-weight: 600;
    margin-bottom: 0;
    color: ${props => props.theme.colors.greyDark};
  }

  p::before,
  p::after {
    content: '“';
    font-family: Georgia;
    font-size: 4rem;
    margin: -1.4rem 0 0 -3rem;
    position: absolute;
    opacity: 0.5;
    color: ${props => props.theme.colors.primary};
  }

  p::after {
    content: '”';
    margin: -0.6rem -5rem 0 0;
  }

  cite {
    font-size: 1.5rem;
  }
`;
const StyledAuthor = styled.div`
  text-align: left;
  color: ${props => props.theme.colors.greyDark};
  font-size: 1.1rem;
  margin-top: 0.6rem;

  span {
    color: ${props => props.theme.colors.primary};
  }
`;

const Quote = ({ input }) => (
  <BlockQuote>
    <div dangerouslySetInnerHTML={{ __html: input.primary.quote.html }} />
    <StyledAuthor>
      {input.primary.author !== null ? <span>-</span> : ''} {input.primary.author}
    </StyledAuthor>
  </BlockQuote>
);

export default Quote;

Quote.propTypes = {
  input: PropTypes.object.isRequired,
};
