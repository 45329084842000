import React from 'react';
import PropTypes from 'prop-types';
import { FlexWrapper, SectionPadding, Content } from './Elements';
import { Section, Title } from "../../components";
import ListingMoyen from "../../components/ListMoyen";

function BodyMoyenListPage({ input }) {
  return (
    <Section bgcolor={input.primary.bgcolor}>
      <FlexWrapper>
        <Title title={input.primary.title.html} color={input.primary.bgcolor}/>
        <ListingMoyen domains={input.items} />
      </FlexWrapper>
    </Section>
  );
}

export default BodyMoyenListPage;

BodyMoyenListPage.propTypes = {
  input: PropTypes.object.isRequired,
};
