import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Wrapper } from '../../components'
import { StyledSeparator, StyledDivider } from './Elements'
import Divider from '../../components/assets/divider.svg'



function BodySeparator({ input }) {
  function Separator() {
    if (input.primary.type !== 'motif') {
      return (
        <Wrapper>
          <StyledSeparator type={input.primary.type} color={input.primary.color} />
        </Wrapper>
      )
    }
    return (
      <StyledDivider>
        <Divider className={input.primary.color} />
      </StyledDivider>
    )
  }

  return <Separator />
}

export default BodySeparator

BodySeparator.propTypes = {
  input: PropTypes.object.isRequired,
}
