import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

const Item = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-around;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
`;

const StyledSubLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  padding: 1rem 2.2rem;
  margin-top: 1rem;
  border: none;
  border-radius: 4px;
  color: ${props => props.theme.colors.greyDark};

  transition: all 0.5s;

  &:hover, &.active {
    //background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.primary};
  }
`;

export default class SubnavItem extends Component {
  render() {
    const { node } = this.props;
    const link = node.link.url;
    const { label } = node;
    return (
      <Item>
        <StyledSubLink activeClassName="active" to={link}>{label}</StyledSubLink>
      </Item>
    );
  }
}

SubnavItem.propTypes = {
  node: PropTypes.object.isRequired,
};
