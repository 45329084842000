import React from 'react';
import PropTypes from 'prop-types';
import { FlexWrapper, Content } from './Elements';
import ListingPartner from '../../components/Listpartner';
import Section from '../../components/Section';
import { Title } from "../../components";

function BodyPartnerListPage({ input }) {
  return (
    <Section>
      <FlexWrapper>
        {/* <Content dangerouslySetInnerHTML={{ __html: input.primary.title.html }}/> */}
        <Title  title={input.primary.title.html} />
        <Content dangerouslySetInnerHTML={{ __html: input.primary.intro.html }} />
      </FlexWrapper>
      <FlexWrapper>
        <ListingPartner partners={input.items} />
      </FlexWrapper>
    </Section>
  );
}

export default BodyPartnerListPage;

BodyPartnerListPage.propTypes = {
  input: PropTypes.object.isRequired,
};
