import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Content = styled.div`
  margin: 0 auto 1rem auto;
  max-width: ${props => props.theme.maxWidthText};
  
      @media (max-width: ${props => props.theme.breakpoints.m}) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  
    .center{
      text-align: center!important;
      color: red!important;
    }
    .center-align{
      text-align: center!important;
      width: 100%;
      display: block;
      padding: 0;
    }
`;

const BodyText = ({ input }) => <Content dangerouslySetInnerHTML={{ __html: input.primary.text.html }} />;

export default BodyText;

BodyText.propTypes = {
  input: PropTypes.object.isRequired,
};
