import styled from '@emotion/styled'

export const StyledForm = styled.form`
  padding: 1rem 0;
`
export const StyledThanks = styled.form`
  padding: 2rem 0;
  font-size: 2rem;
  font-weight: bold;
  color: ${props => props.theme.colors.greyLight};
`

export const StyledInput = styled.input`
  min-width: 400px;
  padding: 1rem 1.6rem;
  margin-right: 2rem;
  border: none;
  border-radius: 6px;
  background-color: ${props => props.theme.colors.greyLight};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    min-width: auto;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    min-width: auto;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`

export const StyledSubmit = styled.input`
  padding: 1rem 2.2rem;
  border: none;
  background-color: ${props => props.theme.colors.greyLight};
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
  }
`
