import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Item = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 22%; /* explanation below */
  margin: 5px;
  
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
  
  
  p, h3{
    text-align: center;
    
  // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //   margin-left: 0;
  //   text-align: center;
  // }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-left: 0;
    text-align: center;
  }

  }
  
  h3{
    min-height: 60px;
    max-width: 260px;
    display: block;
    color: ${props => props.theme.colors.footer};
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.7rem;
    margin: 0 auto 0.6rem auto;
    text-align: center;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1rem;
  }
  
  // &:nth-child(even){
  //   flex-direction: row-reverse;
  //   p, h3{
  //     margin-left: 0;
  //     margin-right: 2rem;
  //    
  //     // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //     //   margin-left: 0;
  //     //   text-align: center;
  //     // }
  //     @media (max-width: ${props => props.theme.breakpoints.s}) {
  //       margin-right: 0;
  //       text-align: center;
  //     }      
  //   }
  // }
    
  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    width: 168px;
    height: 168px; 
    margin: 0 auto;
  }
  
  img{
    max-width: 168px;
    max-height: 168px;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid ${props => props.theme.colors.footer};
  }
`
const Pict = styled.div`
  margin-bottom: 1rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: auto;
    margin-left: auto;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: auto;
    margin: 0 auto 1rem 0;
    text-align: center;
  }
`
const Infos = styled.div``

export default class ListItemText extends Component {
  render() {
    const { node } = this.props
    // const { name } = node.title.html
    // const { surname } = node.data
    // const bio = node.data.bio.html
    // const desc = node.data.description.html
    return (
      <Item>
        <Pict>
          <Img fluid={node.image.mini.localFile.childImageSharp.fluid} />
        </Pict>
        <Infos>
          <div dangerouslySetInnerHTML={{ __html: node.title.html }} />
          {/*<div dangerouslySetInnerHTML={{ __html: node.text.html }} />*/}
          {/*<div dangerouslySetInnerHTML={{ __html: desc }} />*/}
        </Infos>
      </Item>
    )
  }
}

ListItemText.propTypes = {
  node: PropTypes.object.isRequired,
}
