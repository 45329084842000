import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ListItemPerson from './ListItemPerson';
import ListItemProfile from "../Listprofile/ListItemProfile";

const List = styled.div`
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  list-style-type: none;
  margin-left: 0;
`;

export default class ListingPerson extends Component {
  render() {
    const { persons } = this.props;
    return (
      <List>
        {persons.map(person => (
          <ListItemPerson key={person.person_selected.uid} node={person.person_selected.document[0]} type={person} />
        ))}
      </List>
    );
  }
}

ListingPerson.propTypes = {
  persons: PropTypes.array.isRequired,
};
