import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import SubnavItem from './SubnavItem';

const List = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  list-style-type: none;
  margin-left: 0;
`;

export default class Subnav extends Component {
  render() {
    const { subnav } = this.props;
    return (
      <List>
        {subnav.map(sub => (
          <SubnavItem key={sub.id} node={sub} />
        ))}
      </List>
    );
  }
}

Subnav.propTypes = {
  subnav: PropTypes.array.isRequired,
};
