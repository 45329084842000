import React from 'react';
import PropTypes from 'prop-types';
import { FlexWrapper, SectionPadding, Content } from './Elements';
import ListingOffre from "../../components/Listoffre";
import ListingListLienOffre from "../../components/ListLienOffre/";
import { Section, Title } from "../../components";

function BodyOffreLienListPage({ input }) {

  function HaveTitle() {
    if (input.primary.titre_du_bloc.text !== null) {
      return (
        <Title  title={input.primary.titre_du_bloc.html} color={input.primary.bgcolor} />
      );
    }
    return null;
  }

  return (
    <Section bgcolor={input.primary.bgcolor}>
      <FlexWrapper>
        <HaveTitle />
        {/*<Content dangerouslySetInnerHTML={{ __html: input.primary.selectedtext.html }} />*/}
      </FlexWrapper>
      <FlexWrapper>
        <ListingListLienOffre offres={input.items} />
      </FlexWrapper>
    </Section>
  );
}

export default BodyOffreLienListPage;

BodyOffreLienListPage.propTypes = {
  input: PropTypes.object.isRequired,
};
