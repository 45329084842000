import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ListItemListLienOffre from './ListItemListLienOffre';

const List = styled.div`
width: 100%;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  list-style-type: none;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  //flex-grow: 6;
`;

export default class ListingListLienOffre extends Component {
  render() {
    const { offres } = this.props;
    return (
      <List>
        {offres.map(offre => (
          <ListItemListLienOffre key={offre.offre.uid} node={offre.offre.document[0]} />
        ))}
      </List>
    );
  }
}

ListingListLienOffre.propTypes = {
  offres: PropTypes.array.isRequired,
};
