import React from 'react';
import PropTypes from 'prop-types';
import { FlexWrapper, SectionPadding, Content } from './Elements';
import ListingPerson from '../../components/Listperson';
import { Section, Title } from "../../components";

function BodyPersonListPage({ input }) {
  return (
    <Section bgcolor={input.primary.bgcolor}>
      <FlexWrapper>
         <Title title={input.primary.title.html } color={input.primary.bgcolor} />
        <Content dangerouslySetInnerHTML={{ __html: input.primary.selectedtext.html }} />
      </FlexWrapper>
      <FlexWrapper>
        <ListingPerson persons={input.items} />
      </FlexWrapper>
    </Section>
  );
}

export default BodyPersonListPage;

BodyPersonListPage.propTypes = {
  input: PropTypes.object.isRequired,
};
