import styled from '@emotion/styled'

export const StyledShare = styled.div`
  width: auto;
  margin: 1rem auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const StyledShareItem = styled.div`
  width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.5s;
  color: #3F383E;

  &:hover {
    color: white;
    cursor: pointer;
  }

  &.facebook {
    &:hover {
      color: ${props => props.theme.colors.facebook};
    }
  }

  &.twitter {
    &:hover {
      color: ${props => props.theme.colors.twitter};
    }
  }

  &.pocket {
    &:hover {
      color: ${props => props.theme.colors.pocket};
    }
  }

  &.mail {
    &:hover {
      color: ${props => props.theme.colors.greyDark};
    }
  }
`
