import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';

export default class Authors extends Component {
  render() {
    const { authors } = this.props;
    return (
      <>
        {authors.map((aut, i) => (
          <React.Fragment key={aut}>
            {!!i && ', '}
            <Link className="author" to={`/authors/${kebabCase(aut)}`}>
              {aut}
            </Link>
          </React.Fragment>
        ))}
      </>
    );
  }
}

Authors.propTypes = {
  authors: PropTypes.array.isRequired,
};
