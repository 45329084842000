import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import GridListItem from './GridListItem';

const List = styled.div`
  margin-top: 0rem;
  margin-bottom: 2rem;
  padding: 2rem 2rem 0 2rem;
  list-style-type: none;
  margin-left: 0;
  display: flex;
  justify-content: space-around;
  background-color: white;
  align-content: center;
  
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
  }
`;

export default class GridList extends Component {
  render() {
    const { items, columns } = this.props;
    return (
      <List>
        {items.map(item => (
          <GridListItem key={item.uid} node={item} columns={columns}/>
        ))}
      </List>
    );
  }
}

GridList.propTypes = {
  items: PropTypes.array.isRequired,
  columns: PropTypes.string.isRequired,
};
