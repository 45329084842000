import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Social from './Social';
import website from '../../../config/website';

const StyledFooter = styled.footer`
  width: 100%;
  padding: 0;
  color: #ffffff;

  margin: 0;
  font-size: 0.8rem;
`;
const FooterTop = styled.footer`
  padding: 1rem 0;
  background-color: ${props => props.theme.colors.greyLight};
`;
const FooterBottom = styled.footer`
  padding: 1rem 0;
  background-color: ${props => props.theme.colors.footer};
`;

const FooterNav = styled.footer`
  width: fit-content;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 1rem 0;
  }
`;

const StyledLink = styled(Link)`
  margin-right: 2rem;
  color: ${props => props.theme.colors.white};
  font-weight: normal;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 1rem 0;
  }
`;

const StyledFooterTopWrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.colors.grey};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 0 2rem 0 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    margin: 0 1rem 0 1rem;
    padding: 2rem 0;
  }
`;

const StyledFooterBottomWrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.colors.white};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 0 2rem 0 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    margin: 0 1rem 0 1rem;
    padding: 2rem 0;
  }
`;

export default class Footer extends Component {
  render() {
    return (
      <StyledFooter>
        <FooterTop>
          <StyledFooterTopWrapper>
            Lyon - Clermont-Ferrand
          </StyledFooterTopWrapper>
        </FooterTop>
        <FooterBottom>
          <StyledFooterBottomWrapper>
            © 2016 - 2020 {website.titleAlt}
            <FooterNav>
              <StyledLink to="/mentions-legales/">Mentions Légales</StyledLink>
              {/* <StyledLink to="/politique-de-privacite/">Politique de privacité</StyledLink> */}
              <StyledLink to="/cookies/">Cookies</StyledLink>
              {/*<StyledLink to="/presse/">Presse</StyledLink>*/}
            </FooterNav>
            <Social />
          </StyledFooterBottomWrapper>
        </FooterBottom>
      </StyledFooter>
    );
  }
}
