import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Section, StyledBg, Overlay, FlexWrapper, Aligner, Content } from './Elements';

function BodyBannerPage({ input }) {
  function HaveLink() {
    if (input.primary.link !== null) {
      return (
        <a rel="noopener noreferrer" target={input.primary.link.target} href={input.primary.link.url}>
          {input.primary.label !== '' ? input.primary.label : 'Voir la suite'}
        </a>
      );
    }
    return null;
  }

  return (
    <Section>
      <StyledBg>
        <Img fluid={input.primary.image.localFile.childImageSharp.fluid} />
      </StyledBg>
      <Overlay>
        <FlexWrapper>
          <Aligner>
            <Content bannerTheme={input.primary.theme} align={input.primary.align}>
              <div dangerouslySetInnerHTML={{ __html: input.primary.bannertitle.html }} />
              <div dangerouslySetInnerHTML={{ __html: input.primary.text.html }} />
              <HaveLink />
            </Content>
          </Aligner>
        </FlexWrapper>
      </Overlay>
    </Section>
  );
}
export default BodyBannerPage;

BodyBannerPage.propTypes = {
  input: PropTypes.object.isRequired,
};
