import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ListLastItem } from './index'

const List = styled.div`
  margin-top: 0;
  padding: 0;
  list-style-type: none;
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;
  //justify-content: flex-start;
`

export default class ListingLast extends Component {
  render() {
    const { last } = this.props
    return (
      <List>
        {last.map(post => {
          const categories = post.node.data.categorie.document[0].data.name
          const { color } = post.node.data.categorie.document[0].data
          const authors = post.node.data.authors.map(c => c.author.document[0].data.name + `, `)
          //
          return <ListLastItem key={post.node.uid} node={post.node} categories={categories} authors={authors} color={color}/>
        })}
      </List>
    )
  }
}

ListingLast.propTypes = {
  last: PropTypes.array.isRequired,
}
