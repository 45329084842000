import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import TruncateMarkup from 'react-truncate-markup';
import { FaTwitter, FaGithub, FaLinkedin, FaRegFilePdf, FaRegFileImage, FaArrowDown, FaRegFileWord, FaRegFileCode, FaRegFileAudio, FaRegFileVideo } from 'react-icons/fa';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem 1rem 0 1rem;
  border: 1px solid ${props => props.theme.colors.greyLight};
  
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
      margin-bottom: 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
    margin-bottom: 1rem;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    text-align: center;
  }

  
  h3{
    margin-bottom: 0.6rem;
  }
     
  .gatsby-image-outer-wrapper, 
  .gatsby-image-wrapper {
    width: 200px;
    height: 140px; 
    margin: 0 auto;
  }
  
  img{
    width: 100%;
    height: auto;
    //max-height: 240px;
    display: inline-block;
    //border: 2px solid ${props => props.theme.colors.beige};
    object-fit: contain!important;
  }
`;
const Pict = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: auto;
    margin-left: auto;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: auto;
    margin: 0 auto 1rem 0;
    text-align: center;
  }
`;
const Links = styled.div`
  width: 100%;
  padding: 0.6rem 1rem 0;
  text-align: center;

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.greyDark};
    margin-right: 0.8rem;
    transition: all 0.5s;
    position: relative;
    padding-left: 1.4rem;

    span {
      color: ${props => props.theme.colors.primary};
      margin-right: 0.3rem;
      transition: all 0.5s;
      position: absolute;
      left: 0;
      opacity: 1;

      &.bounce {
        opacity: 0;
        animation: bounce 0.8s infinite ease-in-out;
      }

      @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-16px);
        }
        60% {
          transform: translateY(0px);
        }
      }
    }

    &:hover {
      color: ${props => props.theme.colors.primary};
      span {
        opacity: 0;
        color: ${props => props.theme.colors.greyDark};
        &.bounce {
          opacity: 1;
        }
      }
    }
  }
`;

const Name = styled.div`
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.4rem;
`;

const Infos = styled.div`
  width: 100%;
  min-height: 190px;
  padding: 1rem 0;
  color: ${props => props.theme.colors.greydark};
  font-size: 1rem;


`;

const Desc = styled.div`
  min-height: 80px;
`;

const Col = styled.div`
  margin-bottom: 2rem;
  position: relative;
  z-index: 100;
  flex-basis: calc(99.9% * 1 / 3 - 1.5rem);
  max-width: calc(99.9% * 1 / 3 - 1.5rem);
  width: calc(99.9% * 1 / 3 - 1.5rem);
  text-align: ${props => (props.center ? 'center' : '')};
  text-align: ${props => (props.right ? 'right' : '')};

  @media (max-width: 1000px) {
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    max-width: calc(99.9% * 1 / 2 - 1rem);
    width: calc(99.9% * 1 / 2 - 1rem);
  }
  @media (max-width: 700px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
`;

export default class ListDocumentItem extends Component {
  render() {
    const { node } = this.props;
    const desc = node.data.desc.html;
    const { links } = node.data;
    return (
      <Col>
        <Item>
          <Pict>
            <Img fluid={node.data.image.localFile.childImageSharp.fluid} />
          </Pict>
          <Infos>
            <Name>{node.data.title}</Name>
            <TruncateMarkup lines={3}>
              <Desc dangerouslySetInnerHTML={{ __html: desc }} />
            </TruncateMarkup>
            <Links>
              {links.map(link => (
                <a key={link.link.id} rel="noreferrer noopener" target="_blank" href={link.link.url} download>
                  <span>
                    {link.type === 'png' ? FaRegFileImage() : ''}
                    {link.type === 'jpg' ? FaRegFileImage() : ''}
                    {link.type === 'svg' ? FaRegFileImage() : ''}
                    {link.type === 'pdf' ? FaRegFilePdf() : ''}
                    {link.type === 'word' ? FaRegFileWord() : ''}
                    {link.type === 'markdown' ? FaRegFileCode() : ''}
                  </span>
                  <span className="bounce">{FaArrowDown()}</span>
                  {link.label}
                </a>
              ))}
            </Links>
          </Infos>
        </Item>
      </Col>
    );
  }
}

ListDocumentItem.propTypes = {
  node: PropTypes.object.isRequired,
};
