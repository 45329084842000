import styled from '@emotion/styled';
import Wrapper from '../../components/Wrapper';
import Section from '../../components/Section';

export const FlexWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
`;

export const Content = styled.div`
  padding-top: 1rem;
`;

export const SectionPadding = styled(Section)`
  padding-top: 3rem;
  padding-bottom: 3rem;

`;
export const ListControl = styled.div`
  text-align: center;

    a {
    width: fit-content;
    justify-items: left;
    font-size: 0.9rem;
    color: ${props => props.theme.colors.greyDark};
    position: relative;


    &:hover {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
    .button{
      text-align: center;
      display: block;
    }
      a{
        font-size: 0.7rem;
        line-height: 2.6rem;
        font-weight: bold;
        text-transform: uppercase;
        transition: all 0.5s;
        display: inline-flex;
        position: relative;
        padding-left: 13px;
        padding-right: 13px;
        border-radius: 3px;
        color: ${props => props.theme.colors.primary};
        border: 2px solid ${props => props.theme.colors.primary};
        transition: all 0.3s;
        margin: 0 auto 1rem auto;
        text-align: center;

          &:hover{
            color: ${props => props.theme.colors.white}!important;
            background-color: ${props => props.theme.colors.primary};
          }
    }
`;
