import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Wrapper from '../../components/Wrapper';
import Section from '../../components/Section';
import Img from 'gatsby-image';

export const FlexWrapper = styled(Wrapper)`
  display: flex;
  padding-top: 3rem;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
`;

export const Content = styled.div`
  padding-top: 1rem;
`;
export const StyledImg = styled(Img)`
  border-radius: 50%;
  max-width: 260px;
  margin: 0 auto;
`;

export const SectionPadding = styled(Section)`
  padding-top: 0rem;
  padding-bottom: 0rem;
`;
export const Item = styled.article`
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.03);
  margin-bottom: 1.45rem;
  transition: all 0.8s;
  display: flex;
  padding: 2rem 0;

  &:hover {
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.1);
  }

  p {
    min-height: 78px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: block;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
`;
export const ItemWrapper = styled.article`
  padding: 1rem;
  font-size: 1rem;
  font-weight: normal;
`;

export const Headline = styled.div`
  margin-bottom: 0.6rem;
  font-size: 0.8rem;
  color: ${props => props.theme.colors.grey};

  &:first-letter {
    text-transform: uppercase;
  }

  a {
    color: ${props => props.theme.colors.black};
    font-style: normal;
    font-weight: normal;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

export const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.greyDark};
  font-style: normal;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.777rem;
  }
`;

export const StyledTitle = styled.div`
  min-height: 3rem;
  margin-bottom: 1rem;
  h2 {
    color: ${props => props.theme.colors.primary};
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 1.7rem;
    margin-bottom: 1.6rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1rem;
  }
`;
export const StyledLinkCat = styled(Link)`
  display: inline-block;
  padding: 0 0 0.2rem 0;
  margin-bottom: 0.8rem;
  color: ${props => props.theme.colors.black};
  font-style: normal;
  font-size: 0.8rem;
  position: relative;

  &:first-letter {
    text-transform: uppercase;
  }

  &:before {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s;
  }

  &.default {
    &:before {
      background-color: ${props => props.theme.colors.primary};
    }
  }

  &.hitRed {
    &:before {
      background-color: ${props => props.theme.colors.hitRed};
    }
  }

  &.hitGreen {
    &:before {
      background-color: ${props => props.theme.colors.hitGreen};
    }
  }

  &.hitOrange {
    &:before {
      background-color: ${props => props.theme.colors.hitOrange};
    }
  }

  &.hitBondi {
    &:before {
      background-color: ${props => props.theme.colors.hitBondi};
    }
  }

  &:hover {
    &:before {
      width: 0;
    }
    &.default {
      &:before {
        color: ${props => props.theme.colors.primary};
      }
    }

    &.hitRed {
      color: ${props => props.theme.colors.hitRed};
    }

    &.hitGreen {
      color: ${props => props.theme.colors.hitGreen};
    }

    &.hitOrange {
      color: ${props => props.theme.colors.hitOrange};
    }

    &.hitBondi {
      color: ${props => props.theme.colors.hitBondi};
    }
  }
`;

export const Col = styled.div`
  position: relative;
  z-index: 100;
  flex-basis: calc(99.9% * 1 / 2 - 1.5rem);
  max-width: calc(99.9% * 1 / 2 - 1.5rem);
  width: calc(99.9% * 1 / 2 - 1.5rem);
  text-align: ${props => (props.center ? 'center' : '')};
  text-align: ${props => (props.right ? 'right' : '')};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: 1rem;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-bottom: 1rem;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    text-align: center;
    display: ${props => (props.noMobile ? 'none' : '')};
  }
`;
