import React, {Component} from 'react';
import styled from '@emotion/styled';
import PropTypes from "prop-types";

const StyledTitle = styled.div`
  width: 100%;
  display: block;
  text-align: center;
  
  h2{
    font-size: 2.2rem;
    padding: 1rem 0 1.2rem 0;
    position: relative;
    color: ${props => (props.white ? '#FFFFFF' : props.theme.colors.title)};
    color: ${props => (props.color === 'turquoise' ? props.theme.colors.white : '')};
    text-align: ${props => (props.center ? 'center' : '')};

  
  &:before {
    content: '';
    width: 110px;
    height: 3px;
    display: inline-block;
    background-color: ${props => (props.white ? '#FFFFFF' : props.theme.colors.primary)};
    background-color: ${props => (props.color === 'turquoise' ? props.theme.colors.white : '')};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    // left: ${props => (props.center ? '50%' : '0')};
    // transform: ${props => (props.center ? 'translateX(-50%)' : '')};

  }
  
  .shadow{
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    font-size: 2.4rem;
    color: #EBEBEC;

    @media (max-width: ${props => props.theme.breakpoints.s}){
      display: none;
    }

  }
  }
  
    h3{
    font-size: 1.6rem;
    padding: 1rem 0 1.2rem 0;
    position: relative;
    color: ${props => (props.white ? '#FFFFFF' : props.theme.colors.title)};
    color: ${props => (props.color === 'turquoise' ? props.theme.colors.white : '')};
    text-align: ${props => (props.center ? 'center' : '')};

  
  &:before {
    content: '';
    width: 110px;
    height: 3px;
    display: inline-block;
    background-color: ${props => (props.white ? '#FFFFFF' : props.theme.colors.primary)};
    background-color: ${props => (props.color === 'turquoise' ? props.theme.colors.white : '')};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    // left: ${props => (props.center ? '50%' : '0')};
    // transform: ${props => (props.center ? 'translateX(-50%)' : '')};

  }
  
  .shadow{
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    font-size: 2.4rem;
    color: #EBEBEC;

    @media (max-width: ${props => props.theme.breakpoints.s}){
      display: none;
    }

  }
  }
  
`

class Title extends Component{
  render(){
    const { title, center, white, color } = this.props;
    return(
      <StyledTitle center={center} white={white} color={color}>
        <div dangerouslySetInnerHTML={{ __html: title}}></div>
      </StyledTitle>
    )
  }
}


Title.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default Title
