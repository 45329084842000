import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ListItemDomainMinimalist from './ListItemDomainMinimalist';

const List = styled.div`
 width: 100%;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  list-style-type: none;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export default class ListDomainMinimalist extends Component {
  render() {
    const { domains } = this.props;
    return (
      <List>
        {domains.map(domain => (
          <ListItemDomainMinimalist key={domain.domaine.uid} node={domain.domaine.document[0]} />
        ))}
      </List>
    );
  }
}

ListDomainMinimalist.propTypes = {
  domains: PropTypes.array.isRequired,
};
