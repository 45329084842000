import React from 'react';
import PropTypes from 'prop-types';
import { FlexWrapper, SectionPadding, Content } from './Elements';
import { Section, Title } from "../../components";
import ListingDomain from "../../components/ListDomain";
import ListDomainMinimalist from "../../components/ListDomainMinimalist";
import { Link } from "gatsby";

function BodyDomaineListPage({ input }) {
  function IsMinimalist() {
    if (input.primary.display !== 'minimalist') {
      return (
        <ListingDomain domains={input.items} />
      );
    }
    return (
      <ListDomainMinimalist domains={input.items} />
    );
  }
  return (
    <Section bgcolor={input.primary.bgcolor}>
      <FlexWrapper>
        <Title title={input.primary.title.html} color={input.primary.bgcolor}/>
        {/*<ListingDomain domains={input.items} />*/}
        {/*<ListDomainMinimalist domains={input.items} />*/}
        <IsMinimalist/>
      </FlexWrapper>
    </Section>
  );
}

export default BodyDomaineListPage;

BodyDomaineListPage.propTypes = {
  input: PropTypes.object.isRequired,
};
